// src/Pagination.js
import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const getPaginationNumbers = () => {
    const pages = [];
    const totalNumbers = 9; // Total numbers to show in pagination (including current, first, last, and ellipsis)
    const halfTotalNumbers = Math.floor(totalNumbers / 2);

    let startPage = Math.max(1, currentPage - halfTotalNumbers);
    let endPage = Math.min(totalPages, currentPage + halfTotalNumbers);

    if (currentPage <= halfTotalNumbers) {
      endPage = totalNumbers;
    }

    if (currentPage + halfTotalNumbers >= totalPages) {
      startPage = totalPages - totalNumbers + 1;
    }

    startPage = Math.max(1, startPage);
    endPage = Math.min(totalPages, endPage);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (startPage > 1) {
      pages.unshift(1);
    }

    if (endPage < totalPages) {
      pages.push(totalPages);
      if (endPage + 1 < totalPages) {
        pages.splice(pages.length - 1, 0, '...');
      }
    }

    if (startPage > 2) {
      pages.splice(1, 0, '...');
    }

    return pages;
  };

  const pageNumbers = getPaginationNumbers();

  return (
    <div className="pagination">
      <button
        className="pagination__button"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        &lt;
      </button>
      {pageNumbers.map((number, index) => (
        <button
          key={index}
          className={`pagination__number ${currentPage === number ? 'active' : ''}`}
          onClick={() => onPageChange(number)}
          disabled={number === '...'}
        >
          {number}
        </button>
      ))}
      <button
        className="pagination__button"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        &gt;
      </button>
    </div>
  );
};

export default Pagination;
