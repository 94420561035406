import { useDataStore } from "../../hooks/useNavStore";
import Spinner from "../Spinner/Spinner";

import searchIcon from '../../assets/search_abu.svg'; // Importing the search icon

const SearchAudioshop = ({ loading = false }) => {
  const { setSearchGlobal, searchGlobal } = useDataStore();

  return (
    <div className="search-audioshop" tabIndex={-1}>

      

      <input
        value={searchGlobal}
        type="text"
        placeholder="Suchen..."
        onChange={(e) => setSearchGlobal(e.target.value)}
        tabIndex="-1" // Prevents the input from being focused when pressing Tab
      />

      {
        searchGlobal && (
          <i
            className={`icon-ns ns-closeGray w-18 h-18 flex-shrink-0 cursor-pointer mx-2`}
            onClick={() => setSearchGlobal("")}
          ></i>

        )
      }


      <div type="submit" disabled={loading}>
        {loading ? (
          <div className="w-7 h-7">
            <Spinner />

          </div>
        ) : (
          <span className="search-icon">
            <img src={searchIcon} alt="Search Icon" className="w-7 h-7" />
          </span>
        )}
      </div>
    </div>
  );
};

export default SearchAudioshop;
