import React, { useEffect, useState } from "react";
import asWhite from "../assets/blr_ashop_2.svg";
import microsoftLogo from "../assets/logos_microsoft.svg";
import microsoftText from "../assets/micro.svg";
import CustomInput from "../components/CustomInput/CustomInput";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Spinner from "../components/Spinner/Spinner";
import axios from "axios";
import { useLoginStore, useDataStore } from "../hooks/useNavStore";

const Login = () => {
  const { instance } = useMsal();

  const { token, setTokenGlobal, setUsername } = useLoginStore();

  const { setEmailAddress, setIsLogin, setIsNewspool } = useDataStore();

  const [loading, setLoading] = useState(null);
  const [err, setError] = useState("");
  const [email, setEmail] = useState(""); // State for email
  const [password, setPassword] = useState(""); // State for password

  //
  const handleLoginRedirect = () => {
    if (loading) return;

    setLoading("365");

    instance.loginRedirect(loginRequest).catch((error) => console.log(error));

    const loginTime = new Date().getTime();
    localStorage.setItem("loginTime", loginTime);

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const login = async (username, password, refresh_token = null) => {
    if (loading) return;

    setEmailAddress(username);

    const url =
      "https://drq39eb793.execute-api.eu-central-1.amazonaws.com/v1/auth";
    setLoading("login");

    const payload = refresh_token
      ? { username, refresh_token }
      : { username, password };

    try {
      const response = await axios.post(url, { ashopNew: true, ...payload });

      // console.log(response, 'authh resepinssnes')

      setUsername(response.data.userName);

      setLoading(false);
      setTokenGlobal(response.data);
      // localStorage.setItem('tokenRC', JSON.stringify(response.data))
      setError(null);

      setIsLogin(true);

      window.history.replaceState(null, "", `${window.location.origin}/`);

      return response.data;
    } catch (error) {
      const msg = error?.response?.data?.message || null;

      if (msg) setError(msg);

      setLoading(false);
    }
  };

  useEffect(() => {
    const currentUrl = window.location.href; // Get the full URL

    if (currentUrl.includes("#/redirect")) {
      // Extract URL parameters after the hash
      const hashFragment = currentUrl.split("#")[1]; // Get everything after #

      const queryString = hashFragment.split("?")[1]; // Get only the query string part

      const params = new URLSearchParams(queryString);

      // Get tokens
      const access_token = params.get("access_token");

      const refresh_token = params.get("refresh_token");
      const userId = params.get("userId");

      // Set global tokens if found
      if (access_token || refresh_token || userId) {
        setTokenGlobal({ access_token, refresh_token, userId });

        window.history.replaceState(null, "", `${window.location.origin}/`);
      } else {
        handleLoginRedirect();
      }
    }

    const params = new URLSearchParams(window.location.search);
    const email = params.get("email");
    const refreshToken = params.get("refresh_token");

    console.log("Email:", email);
    console.log("Refresh Token:", refreshToken);

    if (email) {
      localStorage.removeItem("login-storage");

      login(email, null, refreshToken);
      setIsNewspool(true);
    }
  }, []);

  return (
    <div className="h-screen">
      <div className="login_container h-full grid grid-cols-2">
        <div className="left flex items-center justify-center">
          <div className="flex flex-col items-center justify-center">
            <p className="title">AudioShop</p>
          </div>
        </div>

        <div className="right">
          <div className="right_container flex flex-col justify-between">
            <img src={asWhite} alt="" className="as_logo_login self-center" />
            {/* <div>
                            <p className='hello'>Willkommen zum neuen Audioshop der BLR!</p>
                        </div> */}
            <p className="wb">Willkommen zum neuen Audioshop der BLR!</p>

            <div className="">
              <CustomInput
                placeholder={"E-Mail"}
                type={"email"}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                errMsg={err ? "error" : ""}
              />
              <CustomInput
                placeholder={"Passwort"}
                type={"password"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                errMsg={err ? "error" : ""}
              />

              {err && <p className="error-login mb-3">{err}</p>}

              <button
                className="login_btn flex justify-center items-center mb-3 cursor-pointer"
                onClick={() => login(email, password)}
                aria-label="login via email"
              >
                {loading === "login" ? <Spinner color="white" /> : <p>Login</p>}
              </button>

              <p className="microsoft_text">
                Sicherer und komfortabler in den neuen Audioshop einloggen: Sog.
                „Single Sign On“ mit Deinem bestehenden Microsoft 365 Account
                anstatt einem eigenen Audiosohop Passwort. Testes es hier mit
                diesem Button:
              </p>

              <button
                className="login_btn microsoft flex justify-center items-center w-full  cursor-pointer"
                onClick={handleLoginRedirect}
                aria-label="login via Microsoft 365"
              >
                {loading === "365" ? (
                  <Spinner />
                ) : (
                  <div className="flex justify-center items-center w-full">
                    <img
                      src={microsoftLogo}
                      alt="Microsoft Logo"
                      className="mr-4"
                    />
                    <img src={microsoftText} alt="Microsoft" />
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
