import React from 'react';
import emailIcon from '../../assets/mail.svg'; // Replace with actual path to your email icon
import lockIcon from '../../assets/lock.svg';  // Replace with actual path to your lock icon

const CustomInput = ({ type, placeholder, value, onChange, errMsg }) => {
  const getIcon = () => {
    if (type === 'email') {
      return <img src={emailIcon} alt="email icon" width="30" height="30" />;
    } else if (type === 'password') {
      return <img src={lockIcon} alt="password icon" width="30" height="30" />;
    }
  };

  return (
    <div className={`custom-input ${errMsg}`}>
      <div className="icon-wrapper">
        {getIcon()}
      </div>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default CustomInput;
