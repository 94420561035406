import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
// “defekt” / “defective”
const resources = {
    EN: {
        translation: {
            "newscasts": "Newscasts",
            "stories": "Stories",
            "profile": "News Profiles",
            "news_confirmed": "Distribution End",
            "new_newscast": "New Newscast",
            "new_template": "New Newscast",
            "special_mode_active": "Special Mode Active",
            "special_mode_off": "Special Mode Inactive",
            "classic_news": "Standard News",
            "profiles_created": "Profiles",
            "von": "{{first}} / {{second}}",
            "expand": "Expand",
            "collapse": "Collapse",
            "Discarded Time": "Discarded Time",
            "Production Start": "Production Start",
            "Production End": "Production End",
            "Released Time": "Released Time",
            "Saved Time": "Saved Time",
            "Confirmation Time": "Confirmation Time",
            "Listening Start": "Listening Start",
            "Distribution End": "Distribution End",
            "Newsstar Production": "Newsstar Production",
            "profiling":"Profiling",
            "search_story": "Search in News Stories",
            "search_cust": "Search Affiliate",
            "clients": "Clients",
            "client": "Client",
            "title": "Title",
            "transcription": "Transcription",
            "news_text": "News Text",
            "create": "Create",
            "type_not_found":"abbreviation (s) not found",
            "otone": "Actualities",
            "oton_singular": "Actuality",
            "released": "Produce",
            "text_digas": "Text",
            "defect": "defect",
            "create_copy":"Create a copy",
            "exclude":"Exclude",
            "follow":"Follow",
            "edit_item": "Edit news item",
            "new_story":"Add Story",
            "deactive_repeat":"deactivate all repeating",
            "ai_gen": "AI Actualities generator",
            "delete":"Delete",
            "save":"Save",
            "male":"Male",
            "female":"Female",
            "start_prod":"Start Production",
            "stop_prod":"Stop Production",
            "reset_news": "Pause Production",
            "oton_player": "Audio File Player",
            "correct_story": "Correct current Story",
            "Next":"Next Story",
            "correct_prev_story":"Correct previous Story",
            "recording":"Recording",
            "total_rec_time":"Total Recording Time",
            "net_rec_time":"Net Recording Time",
            "record_all":"Produce all",
            "enter_pc_name":"Please enter your computer name",
            "empty":"empty",
            "fol":"fol",
            "exc":"exc",
            "copy_to": "Create a Copy to",
            "copy_prev": "Copy from previous stories",
            "no_4_hours":"No story available from the last 4 hours.",
            "upload_sb":"Actualities Upload",
            "preview":"Preview Play",
            "full_play":"Play",
            "save_next":"Save & Next",
            "search_type":"Search News Type",
            "traffic": "Traffic",
            "estimated":"Estimated time",
            "word":"Word",
            "words":"Words",
            "city":"City",
            "time":"Time",
            "archive":"Archive",
            "clock":"Clock",
            "directory":"Directory",
            "timings":"Timings",
            "connection": "Connection",
            "choose_file":"Choose a file or drag & drop it here",
            "editorial":"Editorial",
            "country_code":"Country Code",
            "access_denied_page":"You do not have access to this page.",
            "contact_admin":"Please reach out to our support team.",
            "language":"Language",
            "inactivity":"Inactivity Warning",
            "idled_warning":"You've been idle for one hour. For your security, you'll be logged out automatically.",
            "tables":"Tables",
            "SIT":"Search in tables",
            "audio_list":"Audio List",
            "action":"Action",
            "title":"Title",
            "cr_date":"Created Date",
            "up_date":"Change Date",
            "duration":"Duration",
            "comment":"Comment",
            "produce_audio":"Produce Audio",
            "search_here":"Search here",
            "showing_results": "Showing results {{first}} of {{second}} entries",
            "soft_delete":"Soft Delete",
            "ready":"Ready",
            "created":"Created",
            "updated":"Updated",
            "created_user":"Created User",
            "updated_user":"Updated User",
            "offer":"Offer",
            "finished_by":"Finished by",
            "hold":"Embargo time",
            "group":"Group",
            "theme":"Theme",
            "sess_exp":"Your session has expired. Please log in again to continue."
        }
    },
    DE: {
        translation: {
            "newscasts": "News Ausgaben",
            "stories": "Meldungen",
            "profile": "Profile",
            "news_confirmed": "Distribution Ende",
            "new_newscast": "Neue Ausgabe",
            "new_template": "Neue Ausgabe",
            "special_mode_active": "Kombi aktiviert",
            "special_mode_off": "Kombi deaktiviert",
            "classic_news": "Classic News",
            "von": "{{first}} von {{second}}",
            "profiles_created": "Profile",
            "expand": "Alle anzeigen",
            "collapse": "Alle schließen",
            "Discarded Time": "Defekt Zeit",
            "Production Start": "Produktion Start",
            "Production End": "Produktion Ende",
            "Released Time": "Freigabe Zeit",
            "Saved Time": "Speichern  Zeit",
            "Confirmation Time": "Bestätigung Zeit",
            "Listening Start": "Abhören Start",
            "Distribution End": "Distribution Ende",
            "Newsstar Production": "Newsstar Produktion",
            "profiling":"Profilerstellung",
            "search_story": "Suche in News Texten",
            "search_cust": "Suche nach Kunden",
            "clients": "Kunden",
            "client": "Kunde",
            "title":"Titel",
            "transcription": "Transkription",
            "news_text": "News Texte",
            "create": "Erstellen",
            "type_not_found":"Kürzel nicht gefunden",
            "otone": "O-Töne",
            "oton_singular": "O-Ton",
            "released": "Produzieren",
            "text_digas": "Text DigAS",
            "defect":"defekt",
            "create_copy":"Erstelle Kopie",
            "exclude":"Ausschluss",
            "follow":"Anschluss",
            "edit_item":"Meldung editieren",
            "new_story":"Neue Meldung",
            "deactive_repeat":"alle Wiederholungen aus",
            "ai_gen": "AI O-Ton Generator",
            "delete":"löschen",
            "save":"Speichern",
            "male":"Männlich",
            "female":"Weiblich",
            "start_prod":"Start Produktion",
            "stop_prod":"Stop Produktion",
            "reset_news": "Produktion pausieren",
            "oton_player": "Audio File Player",
            "correct_story": "Korrektur aktuelle Meldung",
            "Next":"Nächste Meldung",
            "correct_prev_story":"Korrektur letzte Meldung",
            "recording":"Aufnahme",
            "total_rec_time":"GESAMT-AUFNAHMEZEIT",
            "net_rec_time":"NETTO-AUFNAHMEZEIT",
            "record_all":"Gesamt Aufnahme",
            "enter_pc_name": "Bitte gebe den Namen des gerade benutzten Computers ein",
            "empty":"leer",
            "fol":"an",
            "exc":"aus",
            "copy_to": "Kopie nach",
            "copy_prev": "Vorherige Meldung kopieren",
            "no_4_hours":"Keine Meldung aus den letzten 4 Stunden verfügbar.",
            "upload_sb":"Ton Upload",
            "preview":"Anspielen",
            "full_play":"Ausspielen",
            "save_next":"Speichern & nächste",
            "search_type":"Suche nach Mel",
            // "search_type":"Suche nach Meldungstype",
            "traffic": "Verkehr",
            "estimated":"Voraussichtliche Zeit",
            "word":"Wort",
            "words":"Wörter",
            "city":"Stadt",
            "time":"Zeit",
            "archive":"Archiv",
            "clock":"Uhr",
            "directory":"Verzeichnis",
            "timings":"Zeiteinteilungen",
            "connection": "Verbindung",
            "choose_file":"Wählen Sie eine Datei aus oder ziehen Sie sie hierher",
            "editorial":"Editorial",
            "country_code":"Landescode",
            "access_denied_page":"Du hast leider keinen Zugriff auf diese Seite.",
            "contact_admin":"Bitte ggf. an die Admins wenden.",
            "language":"Sprache",
            "inactivity":"Inaktivität",
            "idled_warning":"Aus Sicherheitsgründen wurdest du automatisch ausgeloggt.",
            "tables":"Tabellen",
            "SIT":"In Tabellen Suchen",
            "audio_list":"Audio Liste",
            "action":"Aktion",
            "title":"Titel",
            "cr_date":"Erstellungszeit",
            "up_date":"Änderungszeit",
            "duration":"Länge",
            "comment":"Kommentar",
            "produce_audio":"Audio Aufnehmen",
            "search_here":"Suchen",
            "showing_results": "Ergebnisse {{first}} von {{second}} Elementen",
            "soft_delete":"Soft löschen",
            "ready":"Sendefertig",
            "created":"Erstellungszeit",
            "updated":"Änderungszeit",
            "created_user":"User Erstellung",
            "updated_user":"User Änderung",
            "offer":"Angebot",
            "finished_by":"Fertig spätestens",
            "hold":"Zurückhalten bis",
            "group":"Gruppe",
            "theme":"Thema",
            "audioshop":"Im Audioshop",
            "ashop":"Im Audioshop",
            "new":"noch kein Audio",
            "keptback":"fertig - zurückgehalten",
            "finished":"fertig - zurückgehalten f",
            "sess_exp":"Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an, um fortzufahren.",
            "Overview":"Ausgaben Übersicht"
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "DE", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;