import React, { useCallback, useEffect, useRef, useState } from "react";
import RecentPlayer from "../RecentPlayer/RecentPlayer";
import Skeleton from "react-loading-skeleton";
import { formatToUSDate24Hour } from "../../helper/dateRelated";
import moment from "moment";
import { useDataStore, usePlayerStore } from "../../hooks/useNavStore";

const RecentList = ({
  list,
  loading,
  type = "",
  ds = "BM",
  catNameProp = "",
  showDesc = true,
}) => {
  const { currentAudio } = usePlayerStore();

  const { searchFloating } = useDataStore();

  // Handle navigation with Arrow Up and Arrow Down
  const handleKeyDown = useCallback(
    (event, index) => {
      if (event.key === "ArrowDown") {
        event.preventDefault(); // Prevent scrolling
        const nextIndex = (index + 1) % list.length; // Calculate next index
        const nextElement = document.querySelector(
          `[data-index="${nextIndex}"]`
        );
        nextElement?.focus(); // Focus the next element
      } else if (event.key === "ArrowUp") {
        event.preventDefault(); // Prevent scrolling
        const prevIndex = (index - 1 + list.length) % list.length; // Calculate previous index
        const prevElement = document.querySelector(
          `[data-index="${prevIndex}"]`
        );
        prevElement?.focus(); // Focus the previous element
      }
    },
    [list]
  );

  const seenGroups = new Set(); // Track displayed grouptext

  return (
    <div className={`recent ${type}`}>
      <div className={`header_table ${type} ${!showDesc ? "four" : ""}`}>
        <p>Audio</p>
        <p>Titel</p>
        {showDesc && <p>Beschreibung</p>}
        <p>Länge</p>
        <p>Erstellungszeit</p>
        {type === "search" && <p>Category</p>}
      </div>

      <div
        className={`list ${type} ${currentAudio ? "audio" : ""} ${
          searchFloating ? "sf" : ""
        } `}
      >
        {list.map((e, idx) => {
          if (loading) {
            return <Skeleton key={`${e.FileID}${idx}`} height={50} />;
          }

          moment.locale("de");

          const date = e.display_timestamp;
          const formattedDate = moment(date).format("DD.MM.YYYY - HH:mm:ss");
          const id = e.fileId;

          const catName = e.category_name;
          const catId = e.cat_id;

          const filename = e.broadcastAudio;

          let duration = e.len_string;

          if (e.len_string === "00:00:00") duration = "";

          const showGroupText = !seenGroups.has(e.grouptext); // Check if grouptext is seen
          if (showGroupText) seenGroups.add(e.grouptext); // Mark it as seen

          const expDate = e?.audio3_presigned_expires || null;

          const givenDate = moment(date);
          const today = moment().startOf("day"); // Removes time part for accurate comparison

          const invalidAvailableAt =
            givenDate.isBefore(today) && !e.previewAudioURL;

          return (
            <React.Fragment key={`${e.FileID}${idx}`}>
              {showGroupText && e.grouptext && (
                <div className="group-text">
                  <div className="flex items-center">
                    <div className="blue-line begin"></div>
                    <p className="cat uppercase">{e.grouptext}</p>
                    <div className="blue-line flex-1 end"></div>
                  </div>
                </div>
              )}
              <RecentPlayer
                dark={idx % 2 === 0}
                title={e.title}
                duration={duration}
                url={e.previewAudioURL}
                date={formattedDate}
                downloadURL={e.broadcastAudioURL}
                image={e.image}
                id={id}
                remark={e.remark}
                type={type}
                categoryName={catName}
                catId={catId}
                filename={filename}
                dataIndex={idx}
                catName={catNameProp}
                ds={ds}
                onKeyDown={(e) => handleKeyDown(e, idx)}
                isFirst={idx === 0}
                expDate={expDate}
                showDesc={showDesc}
                invalidAvailableAt={invalidAvailableAt}
              />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default RecentList;
