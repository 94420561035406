import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import BasicPlayerImg from "../BasicPlayerImg/BasicPlayerImg";
import Modal from "../Modal/Modal";
import RecentList from "../RecentList/RecentList";
import { useDataStore, usePlayerStore } from "../../hooks/useNavStore";
import moment from "moment";

const MultipleCategoriesImg = ({ data }) => {
  const columnCount = data?.length;

  const { setCurrentAudio } = usePlayerStore();

  const { textOnly, setAct, emailAddress } = useDataStore();

  const [list, setList] = useState([]);
  const [title, setTitle] = useState(null);
  const [show, setShow] = useState(false);

  const closeModal = () => {
    setShow(false);
    // setCurrentAudio(null)
  };

  const ridLocal = localStorage.getItem("rid") || null;
  const [RID, setRID] = useState(ridLocal);

  const activityWs = (type) => {
    let action = "activity";
    let data = {
      action: action,
      data: {
        rId: RID,
        newsType: "ASP1",
        email: emailAddress,
        type,
      },
    };
    // sendMessage(JSON.stringify(data));
    setAct(JSON.stringify(data));
  };

  return (
    <div className="dpa_audio">
      <div
        className="dpa_container"
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
        }}
      >
        {data?.length > 0 &&
          data.map((e, idx) => {
            const newUuid = uuidv4();

            const arr = e.data.slice(0, e.block_rows);

            // return null

            return (
              <div key={newUuid} className="container_multi_img">
                <div className="flex items-center justify-between mb-2">
                  <button
                    onKeyDown={(evt) => {
                      if (evt.key === "Enter") {
                        console.log(e.data, "edata");
                        setList(e.data);
                        setShow(true);
                        setTitle(e.category_name);

                        activityWs(`More: ${e.category_name}`);
                      }
                    }}
                    tabIndex={0}
                    className="header "
                    aria-label={e.category_name}
                  >
                    {e.category_name}
                  </button>
                  <button
                    className="header multi_img cursor-pointer"
                    onClick={() => {
                      setList(e.data);
                      setShow(true);
                      setTitle(e.category_name);
                      activityWs(`More: ${e.category_name}`);
                    }}
                    aria-label="mehr"
                    onKeyDown={(evt) => {
                      if (evt.key === "Enter") {
                        console.log(e.data, "edata");
                        setList(e.data);
                        setShow(true);
                        setTitle(e.category_name);
                        activityWs(`More: ${e.category_name}`);
                      }
                    }}
                    tabIndex={0}
                  >
                    mehr...
                  </button>
                </div>

                {arr.map((j, idx) => {
                  const duration = j.len_string;
                  const title = j.title;

                  const date = j.display_timestamp;

                  // let timeOnly = date.toLocaleTimeString('en-US', {
                  //     hour: '2-digit',
                  //     minute: '2-digit',
                  //     second: '2-digit',
                  //     hour12: false
                  // });

                  moment.locale("de"); // Set the locale to German

                  // const date = '2024-11-04 02:51:05';
                  const timeOnly = moment(date).format("DD.MM. HH:mm");

                  const isYesterdayOrOlder = false;
                  // const isYesterdayOrOlder = moment(j.display_timestamp).isBefore(moment().startOf('day').subtract(1, 'days'));

                  // if (isYesterdayOrOlder) {
                  //     timeOnly = moment(j.display_timestamp).format("DD.MM.YYYY");
                  // }

                  const id = j.fileId;

                  const catId = e.cat_id;
                  const filename = e.broadcastAudio;

                  const expDate = j.audio3_presigned_expires;

                  return (
                    <BasicPlayerImg
                      duration={duration}
                      time={timeOnly}
                      catId={catId}
                      title={title}
                      key={id}
                      url={j.previewAudioURL}
                      img={j.image}
                      remark={j.remark}
                      isYesterdayOrOlder={isYesterdayOrOlder}
                      id={id}
                      filename={filename}
                      downloadURL={j.broadcastAudioURL}
                      catNameProp={e.category_name}
                      expDate={expDate}
                    />
                  );
                })}
              </div>
            );
          })}
      </div>

      {show && (
        <Modal
          overlay
          title={title}
          onClose={closeModal}
          // showHeader={false}
        >
          <RecentList
            catNameProp={title}
            list={list}
            loading={false}
            key={"2"}
          />
        </Modal>
      )}
    </div>
  );
};

export default MultipleCategoriesImg;
