import React from 'react'

const TooltipRemark = ({ text, style = "", title = "" }) => {
    const displayTextWithNewlines = (text) => {
        const renderTextWithBreaks = (text) => {
          return text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ));
        };
      
        return <p>{renderTextWithBreaks(text)}</p>;
    }

    return (
        <div className={`rounded-lg tooltip_remark `}>
            <p className='title_toop'>{title}</p>

            <div className={`text ${style}`}>
                {displayTextWithNewlines(text)}

            </div>
        </div>
    )
}

export default TooltipRemark