import axios from "axios";
import { BASE_API_URL } from "../api/api";
import { getToken } from "./getToken";
import { jwtDecode } from "jwt-decode";

export async function fetchData(url, params = {}, token = "", method = "get") {
  try {
    // Set up headers with Bearer token if provided
    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      // headers["Authorization"] = `Bearer eeeeee`;
      headers["Authorization"] = `Bearer ${token}`;
    }

    // Set up Axios request options
    const options = {
      headers: headers,
    };

    let response;

    // Handle different HTTP methods
    if (method.toLowerCase() === "get") {
      options.params = params;
      response = await axios.get(url, options);
    } else if (method.toLowerCase() === "post") {
      response = await axios.post(url, params, options);
    } else if (method.toLowerCase() === "put") {
      response = await axios.put(url, params, options);
    } else if (method.toLowerCase() === "delete") {
      options.data = params; // For DELETE requests with a request body
      response = await axios.delete(url, options);
    } else {
      throw new Error(`Unsupported method: ${method}`);
    }

    // Return the response data
    return response.data;
  } catch (error) {
    console.error("Fetch data error:", error);
    throw error;
  }
}

export const getTypeList = async (newsType, subEdition) => {
  const params = {
    method: "GET",
    newsType,
    subEdition,
  };
  try {
    const data = await fetchData(
      `${BASE_API_URL}/v1/newsItemTypesList`,
      params
    );
    //   localStorage.setItem("typeList", JSON.stringify(data.data))

    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export async function refreshToken(token, userId, type = "refreshToken") {
  try {
    const response = await axios.get(
      `https://drq39eb793.execute-api.eu-central-1.amazonaws.com/v1/${type}`,
      {
        params: {
          refreshToken: token,
          userId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error refreshing token:", error);
    throw error;
  }
}

export const downloadStatistic = (params, token) => {
  const url = "https://fra-as-api.radio.cloud/v1/downloadStatistic";

  fetchData(url, params, token, "post")
    .then((data) => console.log(data))
    .catch((err) => console.log(err));
};
