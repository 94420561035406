import React, { useState } from "react";
import BasicPlayer from "../BasicPlayer/BasicPlayer";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { useDataStore, usePlayerStore } from "../../hooks/useNavStore";
import Modal from "../Modal/Modal";
import RecentList from "../RecentList/RecentList";

const MultipleCategories = ({ data }) => {
  const columnCount = data?.length;

  const { setCurrentAudio } = usePlayerStore();
  const { textOnly, setAct, emailAddress } = useDataStore();

  const [list, setList] = useState([]);
  const [title, setTitle] = useState(null);
  const [show, setShow] = useState(false);

  const closeModal = () => {
    setShow(false);
    // setCurrentAudio(null)
  };

  const ridLocal = localStorage.getItem("rid") || null;
  const [RID, setRID] = useState(ridLocal);

  const activityWs = (type) => {
    let action = "activity";
    let data = {
      action: action,
      data: {
        rId: RID,
        newsType: "ASP1",
        email: emailAddress,
        type,
      },
    };
    // sendMessage(JSON.stringify(data));
    setAct(JSON.stringify(data));
  };

  return (
    <div className="dpa_audio">
      <div
        className="dpa_container"
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
        }}
      >
        {data?.length > 0 &&
          data.map((e, idxE) => {
            const newUuid = uuidv4();

            const arr = e.data.slice(0, e.block_rows * e.column_count);
            // const arr = e.data.slice(0,e.block_rows*e.column_count*e.column_count)

            const colCount =
              e.column_count === 1
                ? e.column_count
                : textOnly
                ? 2
                : e.column_count;

            return (
              <div key={newUuid}>
                {/* <h1 className='header mb-4'>{e.category_name}</h1> */}
                <div className="flex items-center justify-between border-bttm-eee mb-2">
                  <button
                    className="header"
                    onKeyDown={(evt) => {
                      if (evt.key === "Enter") {
                        setList(e.data);
                        setShow(true);
                        setTitle(e.category_name);
                      }
                    }}
                    aria-label={e.category_name}
                    tabIndex={0}
                  >
                    {e.category_name}
                  </button>
                  <button
                    className="header multi_img cursor-pointer"
                    onClick={() => {
                      setList(e.data);
                      setShow(true);
                      setTitle(e.category_name);
                      activityWs(`More: ${e.category_name}`);
                    }}
                    tabIndex={0}
                    aria-label="mehr"
                  >
                    mehr...
                  </button>
                </div>

                <div
                  className=""
                  style={{
                    display: "grid",
                    gridTemplateColumns: `repeat(${colCount}, 1fr)`,
                    rowGap: "8px",
                    columnGap: "6px",
                  }}
                >
                  {arr.map((j, idxJ) => {
                    const duration = j.len_string;
                    const title = j.title;

                    const date = j.display_timestamp;

                    moment.locale("de"); // Set the locale to German

                    // const date = '2024-11-04 02:51:05';
                    const timeOnly = moment(date).format("DD.MM. HH:mm");

                    const isYesterdayOrOlder = false;

                    // if (isYesterdayOrOlder) {
                    //     timeOnly = moment(j.display_timestamp).format("DD.MM.YYYY");
                    // }

                    const id = j.fileId;
                    // const id = `${j.fileId}-${idxE}-${idxJ}`
                    const catId = e.cat_id;

                    const filename = e.broadcastAudio;

                    const expDate = j.audio3_presigned_expires;

                    return (
                      <BasicPlayer
                        duration={duration}
                        time={timeOnly}
                        title={title}
                        key={id}
                        catId={catId}
                        id={id}
                        url={j.previewAudioURL}
                        remark={j.remark}
                        isYesterdayOrOlder={isYesterdayOrOlder}
                        downloadURL={e.broadcastAudioURL}
                        filename={filename}
                        expDate={expDate}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>

      {show && (
        <Modal
          overlay
          title={title}
          onClose={closeModal}
          // showHeader={false}
        >
          <RecentList
            catNameProp={title}
            list={list}
            loading={false}
            key={"2"}
          />
        </Modal>
      )}
    </div>
  );
};

export default MultipleCategories;
