import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import {
  useDataStore,
  useLoginStore,
  usePlayerStore,
} from "../../hooks/useNavStore"; // Zustand store for audio control
import { Tooltip as ReactTooltip } from "react-tooltip";
import TooltipRemark from "../TooltipRemark/Tooltip";
import { downloadStatistic } from "../../helper/fetchData";
import { copyToClipboard } from "../../helper/dateRelated";
import useWebSocket from "react-use-websocket";
import { SOCKET_URL } from "../../helper/const";
import Spinner from "../Spinner/Spinner";

const BigImageGreen = ({
  title,
  time,
  img,
  url,
  duration,
  downloadURL,
  isYesterdayOrOlder,
  remark,
  id,
  isLastInGroup = false,
  filename,
  catId,
  fileId = null,
  category,
  expDate = "",
}) => {
  const {
    urlFile,
    isPlaying,
    currentDuration,
    totalDuration,
    showPlayer,
    playAudio,
    pauseAudio,
    stopAudio,
    currentAudio,
    downloadAudio,
    updatedAudios,
    isLoading,
    loadingType,
    setLoadingType,
  } = usePlayerStore();

  const { tokenZ } = useLoginStore();
  const [showCopy, setShowCopy] = useState(false);

  const { dataIsNew, setDataIsNew, emailAddress, setAct } = useDataStore();
  const elementRef = useRef(null);
  const timeoutRef = useRef(null);

  const params = {
    customer_id: tokenZ.userId,
    // "reference": "user",
    cat_id: catId,
    file_id: id,
    packagename: filename,
    title: title,
    trigger: "BR",
    user_realm: tokenZ.userId,
  };

  const ridLocal = localStorage.getItem("rid") || null;
  const [RID, setRID] = useState(ridLocal);

  const { sendMessage, lastMessage, readyState } = useWebSocket(SOCKET_URL, {
    share: true,
    shouldReconnect: () => true,
    heartbeat: {
      message: "ping",
      returnMessage: "pong",
      interval: 2 * 60 * 1000,
      timeout: 10 * 60 * 1000,
    },
  });

  const activityWs = (type) => {
    let action = "activity";
    let data = {
      action: action,
      data: {
        rId: RID,
        newsType: "ASP1",
        email: emailAddress,
        type,
      },
    };
    setAct(JSON.stringify(data));
  };

  const theUrl = (id) => {
    if (updatedAudios.length > 0) {
      const apt = updatedAudios.find((e) => +e.fileId === +id);

      if (apt) {
        return apt.previewAudioURL;
      } else {
        return url;
      }
    } else {
      return url;
    }
  };

  const checkIsPlaying = (id) => {
    if (!currentAudio) return false;

    if (updatedAudios.length > 0) {
      const apt = updatedAudios.find((e) => +e.fileId === +id);

      if (apt?.previewAudioURL === currentAudio?.src) return true;
    } else {
      return false;
    }
  };

  const infoActivity = `${category} ${title}`;

  return (
    <div
      className={`green_image flex flex-col justify-between ${
        isLastInGroup ? "last" : ""
      } relative`}
      style={{
        background: `linear-gradient(180deg, rgba(44, 44, 44, 0.10) 33%, #11B37B 100%), url(${img}) lightgray 50% / cover no-repeat`,
      }}
    >
      <div></div>

      {/* {true && <p className='absolute new_blue'>NEW</p>} */}
      {/* {(dataIsNew === fileId) && <p onMouseEnter={() => setDataIsNew(null)} className='absolute new_blue'>NEW</p>} */}

      <div>
        <div className="flex items-center">
          <div className="w-5 h-5 flex items-center justify-center">
            {+isLoading === +fileId && loadingType === "play" ? (
              <Spinner color="white" />
            ) : (
              <i
                className={`icon-ns cursor-pointer ${
                  isPlaying &&
                  (currentAudio?.src === url || checkIsPlaying(fileId))
                    ? "ns-stopWhite"
                    : "ns-playWhite"
                } w-18 h-18`}
                onClick={() => {
                  playAudio(theUrl(fileId), fileId, title);
                  activityWs(
                    `${
                      isPlaying &&
                      (currentAudio?.src === url || checkIsPlaying(fileId))
                        ? `Pause: ${infoActivity}`
                        : `Play: ${infoActivity}`
                    }`
                  );

                  setLoadingType("play");
                }}
              ></i>
            )}
          </div>

          <p className="duration_as">{duration}</p>

          <div className="w-5 h-5 flex items-center justify-center mr-auto">
            {+isLoading === +fileId && loadingType === "download" ? (
              <Spinner color="white" />
            ) : (
              <i
                onClick={() => {
                  downloadAudio(fileId, params, downloadURL, expDate);
                  activityWs(`Download: ${infoActivity}`);

                  setLoadingType("download");
                }}
                className="icon-ns cursor-pointer ns-downloadWhite w-18 h-18 "
              ></i>
            )}
          </div>

          <div className="flex items-center">
            <i
              className={`icon-ns ns-${
                isYesterdayOrOlder ? "calendar" : "clock"
              }White w-18 h-18 mr-1`}
            ></i>

            <p className="time">{time}</p>
          </div>
        </div>
        <div
          id={`tooltip-type-${id}`}
          onClick={() => {
            setShowCopy(id);
            // activityWs(`Read Text: ${infoActivity}`);
          }}
          onMouseLeave={() => setShowCopy(null)}
          className="flex items-center mt-3"
        >
          <p className="title line-clamp-1 cursor-default">
            {title.replace(/^\(.*?\)\s*/, "")}
          </p>

          {showCopy === id && (
            <i
              className={`icon-ns flex-shrink-0 ns-copyRectangleW w-24 h-24 cursor-pointer`}
              onClick={() => {
                copyToClipboard(title, remark);
                activityWs(`Copy Text: ${infoActivity}`);
              }}
            ></i>
          )}
        </div>
      </div>

      {ReactDOM.createPortal(
        <ReactTooltip
          anchorSelect={`#tooltip-type-${id}`}
          style={{
            zIndex: 99,
            padding: 0,
            backgroundColor: "#eaeded",
            color: "#333",
            borderRadius: 12,
            background: "#fff",
            boxShadow: "0px 4px 11px 3px rgba(0, 0, 0, 0.22)",
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
          }}
          openOnClick
          closeEvents={{
            mouseleave: true,
          }}
          place="right"
          clickable
          opacity={1}
          content={<TooltipRemark title={title} text={remark} />}
        />,
        document.body // Append to document.body for unrestricted display
      )}
    </div>
  );
};

export default BigImageGreen;
