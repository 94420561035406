import aby from "../assets/aby.svg";
import rca from "../assets/rca.svg";
import sky from "../assets/sky.svg";
import { getExpTime } from "./getToken";
import n2words from "n2words";

export const grayShadow =
  "1px 4px 20px 10px rgba(11, 11, 11, 0.40) inset, 1px 0px 16px 0px rgba(206, 206, 206, 0.57), 1px 1px 6px 2px rgba(22, 22, 22, 0.20)";
export const prodActive =
  "1.186px 4.746px 21.88px 6.54px rgba(11, 11, 11, 0.49) inset, 1.186px 0px 4.746px 0px rgba(206, 206, 206, 0.47), 1.186px 1.186px 10.678px -1.63px rgba(22, 22, 22, 0.50)";
export const otonBgCol = "linear-gradient(270deg, #2C3991 0%, #26A0DA 91.85%)";
export const theButtons = [
  {
    id: "1",
    number: "4",
    title: "start_prod",
    bgColor: "#0DB07D",
    bShadow: prodActive,
  },
  {
    id: "2",
    number: "1",
    title: "stop_prod",
    bgColor: "#0469AE",
    bShadow: prodActive,
  },
  // { id: '3', number:"5" , title: "correct_prev_story", bgColor:"#9496A1", bShadow: grayShadow},
  {
    id: "4",
    number: "2",
    title: "correct_story",
    bgColor: "#2098C8",
    bShadow:
      "1.186px 4.746px 21.88px 6.54px rgba(11, 11, 11, 0.49) inset, 1.186px 0px 4.746px 0px rgba(206, 206, 206, 0.47), 1.186px 1.186px 10.678px -1.63px rgba(22, 22, 22, 0.50)",
  },
  // { id: '5', number:"6" , title: "reset_news", bgColor:"#8145DE", bShadow: prodActive },
  {
    id: "6",
    number: "3",
    title: "oton_player",
    bgColor: otonBgCol,
    bShadow: prodActive,
  },
];

export const btnDisabled = [
  {
    id: "1",
    number: "4",
    title: "start_prod",
    bgColor: "#9496A1",
    bShadow: prodActive,
  },
  {
    id: "2",
    number: "1",
    title: "stop_prod",
    bgColor: "#9496A1",
    bShadow: prodActive,
  },
  // { id: '3', number:"5" , title: "correct_prev_story", bgColor:"#9496A1", bShadow: grayShadow},
  {
    id: "4",
    number: "2",
    title: "correct_story",
    bgColor: "#9496A1",
    bShadow:
      "1.186px 4.746px 21.88px 6.54px rgba(11, 11, 11, 0.49) inset, 1.186px 0px 4.746px 0px rgba(206, 206, 206, 0.47), 1.186px 1.186px 10.678px -1.63px rgba(22, 22, 22, 0.50)",
  },
  // { id: '5', number:"6" , title: "reset_news", bgColor:"#9496A1", bShadow: prodActive },
  {
    id: "6",
    number: "3",
    title: "oton_player",
    bgColor: "#9496A1",
    bShadow: prodActive,
  },
];

export const wsOptions = {
  // hideScrollbar: true,
  // normalize: true,
  barGap: 1,
  barRadius: 2,
  barWidth: 2,
  height: 101,
};

export const grayButtons = [
  {
    color: "#c0c0c0",
    label: "",
    order: 1,
    active: false,
  },
  {
    color: "#c0c0c0",
    label: "",
    order: 2,
    active: false,
  },
  {
    color: "#c0c0c0",
    label: "",
    order: 3,
    active: false,
  },
  {
    color: "#c0c0c0",
    label: "",
    order: 4,
    active: false,
  },
  {
    color: "#c0c0c0",
    label: "",
    order: 5,
    active: false,
  },
  {
    color: "#c0c0c0",
    label: "",
    order: 6,
    active: false,
  },
];

export const extraMenus = [
  {
    backcolor: "16777215",
    description: "Antenne Prod",
    forecolor: "0",
    icon: "icon-ns ns-classicNews w-46 h-46",
    id: "11",
    navigation: "/news",
    newsType: "CN",
    smallIcon: "icon-ns ns-classicNews w-20 h-20",
    subEdition: 3,
    imageUrl: aby,
    network: "aby",
    label: "Prod",
  },
  {
    backcolor: "16777215",
    description: "Antenne Test",
    forecolor: "0",
    icon: "icon-ns ns-classicNews w-46 h-46",
    id: "12",
    navigation: "/news",
    newsType: "CN",
    smallIcon: "icon-ns ns-classicNews w-20 h-20",
    subEdition: 4,
    imageUrl: aby,
    network: "aby",
    label: "Test",
  },
  {
    backcolor: "16777215",
    description: "RCA OnAir 1",
    forecolor: "0",
    icon: "icon-ns ns-classicNews w-46 h-46",
    id: "14",
    navigation: "/news",
    newsType: "CN",
    smallIcon: "icon-ns ns-classicNews w-20 h-20",
    subEdition: 5,
    imageUrl: rca,
    label: "Prod",
    network: "rca",
  },
  {
    backcolor: "16777215",
    description: "RCA OnAir 2",
    forecolor: "0",
    icon: "icon-ns ns-classicNews w-46 h-46",
    id: "15",
    navigation: "/news",
    newsType: "CN",
    smallIcon: "icon-ns ns-classicNews w-20 h-20",
    subEdition: 6,
    imageUrl: rca,
    label: "Prod",
    network: "rca",
  },
  {
    backcolor: "16777215",
    description: "RCA Test",
    forecolor: "0",
    icon: "icon-ns ns-classicNews w-46 h-46",
    id: "15",
    navigation: "/news",
    newsType: "CN",
    smallIcon: "icon-ns ns-classicNews w-20 h-20",
    subEdition: 7,
    imageUrl: rca,
    label: "Test",
    network: "rca",
  },
  {
    backcolor: "16777215",
    description: "Skyview POC",
    forecolor: "0",
    icon: "icon-ns ns-classicNews w-46 h-46",
    id: "13",
    navigation: "/news",
    newsType: "TN",
    smallIcon: "icon-ns ns-classicNews w-20 h-20",
    subEdition: 7,
    imageUrl: sky,
    network: "sky",
    label: "Prod",
  },
];

export const rcaMenu = [
  {
    backcolor: "16777215",
    description: "RCA OnAir 1",
    forecolor: "0",
    icon: "icon-ns ns-classicNews w-46 h-46",
    id: "14",
    navigation: "/news",
    newsType: "CN",
    smallIcon: "icon-ns ns-classicNews w-20 h-20",
    subEdition: 5,
    imageUrl: rca,
    label: "Prod",
    network: "rca",
  },
  {
    backcolor: "16777215",
    description: "RCA OnAir 2",
    forecolor: "0",
    icon: "icon-ns ns-classicNews w-46 h-46",
    id: "15",
    navigation: "/news",
    newsType: "CN",
    smallIcon: "icon-ns ns-classicNews w-20 h-20",
    subEdition: 6,
    imageUrl: rca,
    label: "Prod",
    network: "rca",
  },
  {
    backcolor: "16777215",
    description: "RCA Test",
    forecolor: "0",
    icon: "icon-ns ns-classicNews w-46 h-46",
    id: "15",
    navigation: "/news",
    newsType: "CN",
    smallIcon: "icon-ns ns-classicNews w-20 h-20",
    subEdition: 7,
    imageUrl: rca,
    label: "Test",
    network: "rca",
  },
];

// export const splitText = (str = "", isDark = false ) => {
//   const textColor = isDark ? "#f3f4f8" : "#000";
//   const elements = [];
//   const parts = str.split(/(\[-\])/);
//   const regex = /\[[\s\S]*?\]/;
//   parts.forEach((part, index) => {
//       if (regex.test(part) && (
//           (parts[index - 1] === "[-]" && parts[index + 1] === "[-]") ||
//           (parts[index - 1] === "[-]")
//       )) {
//           elements.push(<span key={index} className='' style={{ color:"#0469AE" }}>{part}</span>);
//       } else {
//           elements.push(<span key={index} style={{ color:textColor }}>{part}</span>);
//       }
//   });

//   return elements;
// };

// export const splitText = (str = "", isDark = false) => {
//   if (!str) return ""
//   const textColor = isDark ? "#f3f4f8" : "#000";
//   const blueColor = "#0469AE";
//   const elements = [];

//   // Define the regex for matching the desired patterns
//   const regex = /\[.*?\]/;

//   // Split the string into parts using the regex and the delimiter "[-]"
//   const parts = str.split(/(\[-\]|\[.*?\])/);

//   let inBlueSegment = false;

//   parts.forEach((part, index) => {
//     if (part === "[-]") {
//       inBlueSegment = true;
//       elements.push(<span key={index} className='' style={{ color: blueColor }}>{part}</span>);
//     } else if (regex.test(part)) {
//       elements.push(<span key={index} className='' style={{ color: blueColor }}>{part}</span>);
//       inBlueSegment = false; // End the blue segment after encountering a regex match
//     } else if (inBlueSegment) {
//       elements.push(<span key={index} className='' style={{ color: blueColor }}>{part}</span>);
//     } else {
//       elements.push(<span key={index} style={{ color: textColor }}>{part}</span>);
//     }
//   });

//   return elements;
// };

export const splitText = (str = "", isDark = false, headline = "") => {
  if (!str) return "";
  const textColor = isDark ? "#f3f4f8" : "#000";
  const blueColor = "#0469AE";
  const elements = [];

  // Define the regex for matching the desired patterns
  const regex = /\[.*?\]/;

  if (headline?.length > 0) {
    elements.push(
      <span
        key="headline"
        style={{
          color: blueColor,
          fontSize: "12px",
          marginRight: "4px",
          fontWeight: "600",
        }}
      >
        {headline}
      </span>
    );
  }

  // Split the string into parts using the regex, the delimiter "[-]", and newlines
  const parts = str.split(/(\[-\]|\[.*?\]|\n+)/);

  let inBlueSegment = false;

  parts.forEach((part, index) => {
    if (part === "[-]") {
      inBlueSegment = true;
      elements.push(
        <span key={index} style={{ color: blueColor }}>
          {part}
        </span>
      );
    } else if (regex.test(part)) {
      elements.push(
        <span key={index} style={{ color: blueColor }}>
          {part}
        </span>
      );
      inBlueSegment = false; // End the blue segment after encountering a regex match
    } else if (part === "\n" || part.match(/^\n+$/)) {
      // Handle multiple newline characters by inserting <br/> for each
      const newlines = part.length;
      for (let i = 0; i < newlines; i++) {
        elements.push(<br key={`${index}-${i}`} />);
      }
    } else if (inBlueSegment) {
      elements.push(
        <span key={index} style={{ color: blueColor }}>
          {part}
        </span>
      );
    } else {
      elements.push(
        <span key={index} style={{ color: textColor }}>
          {part}
        </span>
      );
    }
  });

  return elements;
};

// export const splitRecText = (str = "", isDark = false) => {
//   const textColor = isDark ? "#f3f4f8" : "#000";
//   const blueColor = "#0469AE";
//   const elements = [];
//   const regex = /\[.*?\]/;
//   const parts = str.split(/(\[-\]|\[.*?\])/);
//   return parts
// }

export const splitRecText = (text) => {
  // Regular expression to match segments that start with [-] and continue until the next [anything]
  const regex = /(\[-\][\s\S]*?\[.*?\])/g;

  // Split the text based on the regex, including the matches in the result
  const result = text.split(regex).filter(Boolean);

  return result;
};

export function extractSegment(hostname) {
  // Remove '.radio.cloud' or '.radio' from the hostname
  const modifiedHostname = hostname.includes(".radio.cloud")
    ? hostname.replace(".radio.cloud", "")
    : hostname.replace(".radio", "");

  // If the modifiedHostname contains a hyphen, extract after the last hyphen
  if (modifiedHostname.includes("-")) {
    const match = modifiedHostname.match(/-(.*)$/);
    return match ? match[1] : "";
  }
  // Otherwise, extract the first part before the dot
  else {
    const match = modifiedHostname.match(/^(.*?)\./);
    return match ? match[1] : "";
  }
}

// export function extractSegment(hostname) {
//   const modifiedHostname = hostname.replace('.radio.cloud', '');

//   const match = modifiedHostname.match(/-(.*?)(-|$)/);
//   const segment = match ? match[1] : "";

//   return segment
// }

export function removeBracketsAndExtension(str) {
  // Remove the brackets and everything inside them
  const withoutBrackets = str.replace(/[()]/g, "");
  // Remove the file extension
  const withoutExtension = withoutBrackets.replace(/\.[^/.]+$/, "");
  // Return the cleaned string
  return withoutExtension;
}

export const checkTokenHasExpired = () => {
  const currentTime = Math.floor(Date.now() / 1000);

  const givenEpochTime = getExpTime();

  // const futureTime = currentTime + 7 * 24 * 60 * 60;

  if (currentTime > givenEpochTime) return true;

  return false;
};

export function generateRandomId() {
  const num1 = Math.floor(Math.random() * 900) + 100;
  const num2 = Math.floor(Math.random() * 900) + 100;
  return `${num1}-${num2}`;
}

export const addLineBreaks = (text = "", fontSize = 22, maxWidth = 950) => {
  const avgCharWidth = fontSize * 0.6; // Average width of a character in px (approximation)
  const maxCharsPerLine = Math.floor(maxWidth / avgCharWidth);

  let formattedText = "";
  let line = "";

  const words = text.split(/(\s+|\[.*?\])/); // Split by spaces or brackets

  for (let i = 0; i < words.length; i++) {
    const word = words[i].trim();

    if (!word) continue;

    // Check if adding the word would exceed the maximum characters per line
    if (line.length + word.length > maxCharsPerLine) {
      formattedText += line.trim() + "\n";
      line = "";
    }

    // If the word itself is too long to fit in a single line but is in brackets, add it as a new line
    if (
      word.startsWith("[") &&
      word.endsWith("]") &&
      word.length > maxCharsPerLine
    ) {
      if (line.trim().length > 0) {
        formattedText += line.trim() + "\n";
        line = "";
      }
      formattedText += word + "\n";
      continue;
    }

    line += word + " ";
  }

  // Add the last line
  formattedText += line.trim();

  return formattedText;
};

export const removeLineBreaks = (text) => {
  return text.replace(/\n/g, " ");
};

export function countWords(text) {
  if (!text) {
    return 0;
  }
  return text.trim().split(/\s+/).length;
}

function cleanText(text) {
  const regex = /(\[-\][\s\S]*?\[.*?\])/g;

  return text.replace(regex, "");
}

// let singleEstimatedDuration = (characterCount * averageMsPerChar) + soundbyteDuration;

export const countCharsRemoveSoundbyte = async (sourceText) => {
  if (!sourceText) return 0;
  try {
    // let textWithPairsRemoved = sourceText.replace(/\(\-\)[\s\S]+?\(\-\)/g, '').replace(/\[\-\][\s\S]+?\[\-\]/g, '');
    // let finalText = textWithPairsRemoved.replace(/\(\-\).*/, '').replace(/\[\-\].*/, '');

    let finalText = cleanText(sourceText);

    // Assume replaceNumbersWithWords is an async function
    finalText = await replaceNumbersWithWords(finalText, "de");

    // Trim and count the characters of the final text
    let characterCount = finalText.replaceAll("  ", " ").trim().length;

    return characterCount;
  } catch (e) {
    console.error(e);
    return sourceText.length;
  }
};

function isUppercase(str) {
  return /^[A-Z]+$/.test(str);
}

function extractBeginningNumber(word) {
  const result = word.match(/^\d+/);
  return result ? result[0] : null;
}

function n2w(input, languageCode) {
  if (input != "") {
    let output = input;
    if (!isNaN(input)) {
      if (parseInt(input) >= 1950 && parseInt(input) <= 1999) {
        let n1 = n2words(parseInt(input.substr(0, 2)), { lang: languageCode });
        let n2 = n2words(parseInt(input.substr(2, 4)), { lang: languageCode });
        if (languageCode == "en") output = `${n1} ${n2}`;
        if (languageCode == "de") output = `${n1} hundert ${n2}`;
        if (languageCode == "id") output = `${n1} ${n2}`;
        if (languageCode == "it") output = `${n1} ${n2}`;
      } else {
        output = n2words(parseInt(input), { lang: languageCode });
      }
    }
    return output;
  } else {
    return input;
  }
}

function replaceNumbersWithWords(input, languageCode) {
  const numbers = input.split(" ");
  if (!numbers) return input;
  let i = 0;
  let textBefore = "";
  let textAfter = "";
  for (let number of numbers) {
    let lastChar = number[number.length - 1];
    if (lastChar == "." || lastChar == "," || lastChar == "%") {
      number = number.substr(0, number.length - 1);
      if (number[number.length - 1] == "%") {
        lastChar = number[number.length - 1];
        number = number.substr(0, number.length - 1);
      }
    }
    if (i > 0) textBefore = numbers[i - 1];
    if (i < numbers.length - 1) textAfter = numbers[i + 1];
    let tbUC = isUppercase(textBefore.replace(/\"/g, "").replace(/\'/g, ""));
    if (textBefore == "") tbUC = false;
    let taUC = isUppercase(textAfter.replace(/\"/g, "").replace(/\'/g, ""));
    if (textAfter == "") taUC = false;
    i++;
    if (!isNaN(number) && number != "" && number != "\n" && !tbUC && !taUC) {
      let numberAsWord = n2w(number, languageCode);
      let lastCharTranslated = "";
      if (lastChar == "%") {
        if (languageCode == "en") lastCharTranslated = "percent";
        if (languageCode == "de") lastCharTranslated = "prozent";
        if (languageCode == "id") lastCharTranslated = "persen";
        if (languageCode == "it") lastCharTranslated = "per cento";
      } else {
        lastChar = "";
      }
      if (textBefore != "") {
        input = input.replace(
          `${textBefore} ${number}${lastChar}`,
          `${textBefore} ${numberAsWord} ${lastCharTranslated}`
        );
      } else {
        input = input.replace(
          `${number}${lastChar}`,
          `${numberAsWord} ${lastCharTranslated}`
        );
      }
    } else {
      let numberAsWord = number;
      if (number.includes("-")) {
        let numberArray = number.split("-");
        let in0 = numberArray[0];
        let in0suffix = "";
        if (in0.includes("°C")) {
          in0 = in0.replace("°C", "");
          if (languageCode == "en") in0suffix = `degree celcius`;
          if (languageCode == "de") in0suffix = `grad celcius`;
          if (languageCode == "id") in0suffix = `derajat celcius`;
          if (languageCode == "it") in0suffix = `gradi celcius`;
        }
        if (in0.includes("°F")) {
          in0 = in0.replace("°F", "");
          if (languageCode == "en") in0suffix = `degree fahrenheit`;
          if (languageCode == "de") in0suffix = `grad fahrenheit`;
          if (languageCode == "id") in0suffix = `derajat fahrenheit`;
          if (languageCode == "it") in0suffix = `gradi fahrenheit`;
        }
        let in1 = numberArray[1];
        let in1suffix = "";
        if (in1.includes("°C")) {
          in1 = in1.replace("°C", "");
          if (languageCode == "en") in1suffix = `degree celcius`;
          if (languageCode == "de") in1suffix = `grad celcius`;
          if (languageCode == "id") in1suffix = `derajat celcius`;
          if (languageCode == "it") in1suffix = `gradi celcius`;
        }
        if (in1.includes("°F")) {
          in1 = in1.replace("°F", "");
          if (languageCode == "en") in1suffix = `degree fahrenheit`;
          if (languageCode == "de") in1suffix = `grad fahrenheit`;
          if (languageCode == "id") in1suffix = `derajat fahrenheit`;
          if (languageCode == "it") in1suffix = `gradi fahrenheit`;
        }
        if (numberArray.length == 2 && (!isNaN(in0) || !isNaN(in1))) {
          let n0 = n2w(in0, languageCode);
          let n1 = n2w(in1, languageCode);
          if (!isNaN(in0) && !isNaN(in1) && in0 != "" && in1 != "") {
            if (languageCode == "en")
              numberAsWord = `${n0} ${in0suffix} until ${n1} ${in1suffix}`;
            if (languageCode == "de")
              numberAsWord = `${n0} ${in0suffix} bis ${n1} ${in1suffix}`;
            if (languageCode == "id")
              numberAsWord = `${n0} ${in0suffix} sampai ${n1} ${in1suffix}`;
            if (languageCode == "it")
              numberAsWord = `${n0} ${in0suffix} fino a ${n1} ${in1suffix}`;
          } else {
            if (languageCode == "en")
              numberAsWord = `${n0} ${in0suffix}- ${n1} ${in1suffix}`;
            if (languageCode == "de")
              numberAsWord = `${n0} ${in0suffix}- ${n1} ${in1suffix}`;
            if (languageCode == "id")
              numberAsWord = `${n0} ${in0suffix}- ${n1} ${in1suffix}`;
            if (languageCode == "it")
              numberAsWord = `${n0} ${in0suffix}- ${n1} ${in1suffix}`;
          }
          input = input.replace(number, numberAsWord);
        }
      } else if (number.includes(",") || number.includes(".")) {
        let numberArray = [];
        if (number.includes(",")) {
          numberArray = number.split(",");
        } else if (number.includes(".")) {
          numberArray = number.split(".");
        }
        let in0 = numberArray[0];
        let in1 = numberArray[1];
        let in1suffix = "";
        if (in1.includes("°C")) {
          in1 = in1.replace("°C", "");
          if (languageCode == "en") in1suffix = ` degree celcius`;
          if (languageCode == "de") in1suffix = ` grad celcius`;
          if (languageCode == "id") in1suffix = ` derajat celcius`;
          if (languageCode == "it") in1suffix = ` gradi celcius`;
        }
        if (in1.includes("°F")) {
          in1 = in1.replace("°F", "");
          if (languageCode == "en") in1suffix = ` degree fahrenheit`;
          if (languageCode == "de") in1suffix = ` grad fahrenheit`;
          if (languageCode == "id") in1suffix = ` derajat fahrenheit`;
          if (languageCode == "it") in1suffix = ` gradi fahrenheit`;
        }
        if (numberArray.length == 2 && (!isNaN(in0) || !isNaN(in1))) {
          let n0 = n2w(in0, languageCode);
          let n1 = n2w(in1, languageCode);
          if (
            !isNaN(in0) &&
            !isNaN(in1) &&
            in0 != "" &&
            in1 != "" &&
            n0 != undefined &&
            n1 != undefined
          ) {
            if (languageCode == "en")
              numberAsWord = `${n0} point ${n1}${in1suffix}`;
            if (languageCode == "de")
              numberAsWord = `${n0} komma ${n1}${in1suffix}`;
            if (languageCode == "id")
              numberAsWord = `${n0} koma ${n1}${in1suffix}`;
            if (languageCode == "it")
              numberAsWord = `${n0} punto ${n1}${in1suffix}`;
            input = input.replace(number, numberAsWord);
          }
        }
      } else {
        let atbNumber = extractBeginningNumber(number);

        if (atbNumber && atbNumber != "" && !tbUC && !taUC) {
          let atbNumberAsWord = n2w(atbNumber, languageCode);
          input = input.replace(atbNumber, atbNumberAsWord);
        }
      }
    }
  }
  return input;
}

export const imgPlanung = [
  "https://tse1.mm.bing.net/th?id=OIP.E7hAyE2BJ1KZMRoYvbJTVgHaEK&pid=Api",
  "https://tse4.mm.bing.net/th?id=OIP.603JRFgUkFRFNyZsessXNwHaEK&pid=Api",
  "https://tse1.mm.bing.net/th?id=OIP.BP4q6WtaKyJKQmLUNBi7bAHaEp&pid=Api",
  "https://tse4.mm.bing.net/th?id=OIP.v8WwgXfRjSSicsImV-ymCAHaFs&pid=Api",
  "https://tse3.mm.bing.net/th?id=OIP.hySJamgvASzER1rZtyzY9QHaEK&pid=Api",
  "https://tse3.mm.bing.net/th?id=OIP.RhIVwEUTkjpOE4Leb1F1igAAAA&pid=Api",
  "https://tse4.mm.bing.net/th?id=OIP.d_n9dmxFgk_bjQAcB9aO2wHaE8&pid=Api",
  "https://tse3.mm.bing.net/th?id=OIP.JzVfDuEI7AyYW--12a9EzQHaEY&pid=Api",
  "https://tse3.mm.bing.net/th?id=OIP.hQWXFxdndv690zUpLFLyagHaEK&pid=Api",
  "https://tse3.mm.bing.net/th?id=OIP.UgJRrVIGP06kv2xPC04tbAHaFj&pid=Api",
  "https://tse3.mm.bing.net/th?id=OIP.V8zD4qs37PR_oTydcogrGQHaE8&pid=Api",
  "https://tse1.mm.bing.net/th?id=OIP.6t3Vk6dAOXVpImzGygmeyAHaJ4&pid=Api",
  "https://tse4.mm.bing.net/th?id=OIP.jQlGmHcVPh786_TMgl2rcgHaEq&pid=Api",
  "https://tse1.mm.bing.net/th?id=OIP.fkw-6TICHF4qCFDiLdAB6AAAAA&pid=Api",
  "https://tse4.mm.bing.net/th?id=OIP.tV0gCqT72EXVoXkOuuggXQHaNK&pid=Api",
  "https://tse4.mm.bing.net/th?id=OIP.LzYkgKZAZ0F1vyRYYADOlQHaE7&pid=Api",
  "https://tse4.mm.bing.net/th?id=OIP.jrg7E4iHIH_qQik0MKiMdwHaE8&pid=Api",
  "https://tse1.mm.bing.net/th?id=OIP.sKFlXzPOdJ50uD2FlSQNwAHaE7&pid=Api",
  "https://tse3.mm.bing.net/th?id=OIP.nG_JwHHMbGnVcYluJCPmSwHaE8&pid=Api",
  "https://tse3.mm.bing.net/th?id=OIP.a8DKVy8OQaRASUdK37yC_QHaE8&pid=Api",
  "https://tse1.mm.bing.net/th?id=OIP.12CfF2etmCNwfBBTPuiDJwHaLH&pid=Api",
  "https://tse3.mm.bing.net/th?id=OIP.qzQ6-Kkp-uI57u5J0xr7tQHaE8&pid=Api",
  "https://tse4.mm.bing.net/th?id=OIP.Iev3wpfSe7PCSzi-MO6s1wHaFj&pid=Api",
  "https://tse1.mm.bing.net/th?id=OIP.h4tsj1fuAmeQyZ9wJXEwhAHaLH&pid=Api",
  "https://tse2.mm.bing.net/th?id=OIP.yEwMxPDjZTUmxzCdooXHwwHaHa&pid=Api",
  "https://tse1.mm.bing.net/th?id=OIP.V_ynl0Ot2v1NEvoFSvnw3QHaHa&pid=Api",
  "https://tse2.explicit.bing.net/th?id=OIP.DmxynkAo2aWAAK9Tr-tKbAHaLl&pid=Api",
  "https://tse4.mm.bing.net/th?id=OIP.GV6ALh93-NDpMeaH40kMMQHaEK&pid=Api",
  "https://tse3.mm.bing.net/th?id=OIP.FIID2yHvB-0EFdpRcfmB8gAAAA&pid=Api",
  "https://tse4.mm.bing.net/th?id=OIP.1okGF2GPvxLv1EKad0NSZAHaMz&pid=Api",
  "https://tse3.mm.bing.net/th?id=OIP.AGzO-NhTXOCzveGkFYca5QHaFm&pid=Api",
  "https://tse2.mm.bing.net/th?id=OIP.oenLuYSt2tb8pc4ewWPB5AHaHa&pid=Api",
  "https://tse1.mm.bing.net/th?id=OIP.ycI5VLnANpQNqVQyIbIw1AAAAA&pid=Api",
  "https://tse4.mm.bing.net/th?id=OIP.562cGRg0HZOVdtTzSdDpGgHaEK&pid=Api",
  "https://tse4.mm.bing.net/th?id=OIP.JxvHHUxX7_w80xUC4Mx1KwHaFj&pid=Api",
];

export const week = [
  { day: "Monday", id: 1 },
  { day: "Tuesday", id: 2 },
  { day: "Wednesday", id: 3 },
  { day: "Thursday", id: 4 },
  { day: "Friday", id: 5 },
  { day: "Saturday", id: 6 },
  { day: "Sunday", id: 7 },
  { day: "", id: 8 },
];

export const breakpointsConfig = {
  320: { slidesPerView: 2, slidesPerGroup: 2 },
  1260: { slidesPerView: 3, slidesPerGroup: 3 },
  1400: { slidesPerView: 4, slidesPerGroup: 4 },
  1600: { slidesPerView: 5, slidesPerGroup: 5 },
};

export const adjustFeaturedData = (data, slidesPerGroup) => {
  let featuredData = [...new Set(data.map((item) => JSON.stringify(item)))].map(
    (item) => JSON.parse(item)
  ); // Remove duplicates

  const remainder = featuredData.length % slidesPerGroup;

  if (remainder === 0) {
    return featuredData; // Do nothing if already correct
  }

  const itemsToAdd = slidesPerGroup - remainder;

  for (let i = 0; i < itemsToAdd; i++) {
    featuredData.push({ ...featuredData[i] }); // Add only the minimum required
  }

  return featuredData;
};

export const SOCKET_URL =
  "wss://wlmgqihk72.execute-api.eu-central-1.amazonaws.com/v1/";

// if (layout === 3) {
//   return (
//     <FeaturedCarousel
//       isNewProp={item?.isNew}
//       key={index}
//       data={item.data}
//       headline={cat}
//       type="top_img"
//     />
//   );
// }

// const counterRef = useRef(0);

// useEffect(() => {
//   console.log(counterRef.current, "<<<<COUNTER");

//   if (counterRef.current === 0) {
//     const exp = {
//       access_token:
//         "eyJlbmMiOiJBMTI4Q0JDLUhTMjU2IiwiYWxnIjoiQTI1NktXIiwia2lkIjoieDFJMDlQdTBMcU9YMUNXbndlQWhIVnVSeG0wYnpTTllwdTBFUzBlajZUSSJ9.Rm6ZRebq-yeBjR6J8EWEmUIGqP1C-LSyHB5S0_OlwoZ0g9APlh9IRg.OH3mjSNHpGgjTSpis4-rkw.W2R_I9tDD_1LuzkOh4KY9ZUwwWdfZZIggvQ5CVwFHeFOe5bkoMQTTrDeZeg3rYGnlkAtfFT9AP03xL3QHYptIT9FAKIkpj3dvmZsXIPg-S8oWGoZsuqy548bWupSBcFJyDWxm_c7gnlJYYHzrSqUib7Pf_1r3vasVLb0DcJKmoiG6DlmihWZpvsIPfVxYhE_sqa5_N1CmU1VTcTHRWtXxgOBUySj2hNgEMSvkrjDE6MIHNcTj6YU3C9UHVL1iY_JsyFcMcd5WuP5-KGEqbDHuxuzNquVszBNftMhZ7_iHtRIP3Z9QCS5RyRR9riKVOS5DK4TgQWhR-vkC6hdtV8aaGY2yIcjzpnERIYxliAtYcs1Ydhd8jUyudltBYHev5cl98a_PiJhvubDUyr-9-6Q5hf7LIQ8VCBQ7VPVek9jUAfMJhqgLmLwEION2ux6M1ZyReQ49OGONUg3VNYl8nC6wmBFUhKd6MrBqe9XVobD-Zn9UwcbLCgemTRLMimkMpTmvAKoEuNgMZE34-nkUH9_4FgEDekYEerfXcB0AtN5mWNNv1_gmZvQYIPztQAoiRogFaG3TS7icxoB8o2nC9ffHC_zkukybzTcLBz-HFXCIohKGo36TRjyCTCjB08jGe0XY-Ad8gAWnlYmSMg1EOOvMH6A3tOJHjHZSP-dj1M1t_etgs3dd1fwc7WZuuJMQY_4d_m_er_KeRy5Y4zJ4uB0UeJORDySkHAcclUCJLLGCd0es4tN7cLTLeozdrTK5kxL24WOEH-7hz6WX0K71PZnNzK_8wmjQkxohFFL3zpAIuQviamK54yuYze7s--wN9PnW0-zmJs3KtARK6DztqrTUqkP7HS6aGMQ4gOQVvqFIsySLC6FeeOIyxrnWjr3cma_JZTc_MEyG3EkI1Ttgoc95qKXHVWOQ1YKobuFY1nYCfuFImjGRQtV0UmRzoCbzjDniWXdcIwaLs7XejlGuBW6lDTAYKWasqHc5YP1pt9eOmuzbiqkx9XEEhuOokYeuKoiN8WX3zl2bQzi0EejJ27d1iaqvBiBWIDUAQHY1jQSzEzyVYpiSSSA6cq8-WTV_hPC8frh2Zgu6vTKlq1DupmeWI90yuKvezJp6TE2fC4SiDx00z1kBauU0NLQZr4W5PbOa1DDGHCXsLVtrHBPfzkt6RNTbzJxvCd9dv-OGWUGIveYGfp-WExBfdSLIU736pIfpgGqBc2YjaBG4i-ObLEhT2VifyzDjq9Oj4OkqisbeP_zgcujAbNawoHTE00oGEo6jjk6aloORxitmzpj2NpiLxUmaN4gnUIDHGsZiNZJhMc.AYKfBzn-09v4M3RI9vR8lg",
//       refresh_token:
//         "7175cab52090f3a283fa90fc5a6adf2e8f9c6c45d675b3f8f5ab0d01528cc9cb3063ca784a17127a4fa48d26a5dfbb28f29d8f5c92806edb6be5af658f11ae64",
//       token_type: "Bearer",
//       expires_in: 1740649132,
//       userId: "63ab7b60-9800-11ef-91f8-0291df4c7fb9",
//       customerName: "Radio.Cloud",
//       customerCity: "Garching/Jakarta",
//       userName: "Joe",
//       officeLogin: false,
//     };

//     console.log("<<<< set TOKEN");

//     setTokenGlobal(exp);
//   }

//   counterRef.current = 1;
// }, []);
