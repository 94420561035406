import React, { useState, useRef } from "react";
import {
  useDataStore,
  useLoginStore,
  usePlayerStore,
} from "../../hooks/useNavStore";
import { Tooltip as ReactTooltip } from "react-tooltip";
import TooltipRemark from "../TooltipRemark/Tooltip";
import { downloadStatistic } from "../../helper/fetchData";
import { copyToClipboard } from "../../helper/dateRelated";
import Spinner from "../Spinner/Spinner";

const BasicPlayer = ({
  duration = "00:00",
  time,
  title = "",
  url,
  isYesterdayOrOlder,
  downloadURL,
  id,
  remark,
  catId,
  filename,
  catName = "",
  expDate = "",
}) => {
  const {
    urlFile,
    isPlaying,
    currentDuration,
    totalDuration,
    showPlayer,
    playAudio,
    pauseAudio,
    stopAudio,
    currentAudio,
    updatedAudios,
    isLoading,
    downloadAudio,
  } = usePlayerStore();
  const { dataIsNew, setDataIsNew, emailAddress, setAct } = useDataStore();
  const { tokenZ, usernameGlobal } = useLoginStore();
  const [showCopy, setShowCopy] = useState(false);
  const [showNewLabel, setShowNewLabel] = useState(dataIsNew); // Show only if id matches dataIsNew
  const elementRef = useRef(null);
  const timeoutRef = useRef(null);

  const [loadingType, setLoadingType] = useState(null);

  const params = {
    customer_id: tokenZ.userId,
    cat_id: catId,
    file_id: id,
    packagename: filename,
    title: title,
    trigger: "BT",
    user_realm: tokenZ.userId,
  };

  const checkIsPlaying = (id) => {
    if (!currentAudio) return false;

    if (updatedAudios.length > 0) {
      const apt = updatedAudios.find((e) => +e.fileId === +id);

      if (apt?.previewAudioURL === currentAudio?.src) return true;
    } else {
      return false;
    }
  };

  const theUrl = (id) => {
    if (updatedAudios.length > 0) {
      const apt = updatedAudios.find((e) => +e.fileId === +id);

      if (apt) {
        return apt.previewAudioURL;
      } else {
        return url;
      }
    } else {
      return url;
    }
  };

  const ridLocal = localStorage.getItem("rid") || null;
  const [RID, setRID] = useState(ridLocal);

  const activityWs = (type) => {
    let action = "activity";
    let data = {
      action: action,
      data: {
        rId: RID,
        newsType: "ASP1",
        email: emailAddress,
        type,
      },
    };
    // sendMessage(JSON.stringify(data));
    setAct(JSON.stringify(data));
  };

  const infoActivity = `${catName} ${title}`;

  return (
    <div
      ref={elementRef}
      className={`basic relative`}
      // className={`basic ${dataIsNew === id ? "blue-bor" : ""} relative`}
      // onMouseEnter={() => setDataIsNew(null)}
    >
      {/* {(dataIsNew === id) && <p className='absolute new_blue'>NEW</p>} */}

      <div className="flex upper">
        <div className="player_container cursor-pointer flex justify-center items-center">
          {+isLoading === +id && loadingType === "play" ? (
            <Spinner />
          ) : (
            <i
              className={`icon-ns ${
                isPlaying && (currentAudio?.src === url || checkIsPlaying(id))
                  ? "ns-stopGreen"
                  : "ns-playGreen"
              } w-24 h-24`}
              onClick={() => {
                setDataIsNew(null);
                playAudio(theUrl(id), id, title);
                setLoadingType("play");
                activityWs(
                  `${
                    isPlaying &&
                    (currentAudio?.src === url || checkIsPlaying(id))
                      ? `Pause: ${infoActivity}`
                      : `Play: ${infoActivity}`
                  }`
                );
              }}
            ></i>
          )}
        </div>

        <div
          data-tooltip-id={`tooltip-type-${id}`}
          onClick={() => {
            setShowCopy(id);
            // activityWs(`Read Text: ${infoActivity}`);
          }}
          onMouseLeave={() => setShowCopy(false)}
          className="flex items-center"
        >
          <p className="title line-clamp-2 cursor-default mr-1">
            {title.replace(/^\(.*?\)\s*/, "")}
          </p>

          {showCopy === id && (
            <i
              className={`icon-ns flex-shrink-0 ns-copyRectangle w-24 h-24 cursor-pointer`}
              onClick={() => {
                copyToClipboard(title, remark);
                activityWs(`Copy Text: ${infoActivity}`);
              }}
            ></i>
          )}
        </div>
      </div>

      <div className="lower">
        <div className="flex items-center">
          <p className="duration_as">{duration}</p>

          {+isLoading === +id && loadingType === "download" ? (
            <div className="w-5 h-5 flex-1 flex">
              <Spinner size="4" />
            </div>
          ) : (
            <i
              onClick={() => {
                downloadAudio(id, params, downloadURL, expDate);
                setLoadingType("download");
                activityWs(`Download: ${infoActivity}`);
              }}
              className="icon-ns ns-downloadGreen cursor-pointer w-20 h-20 mr-auto"
            ></i>
          )}

          <div className="flex items-center">
            <i className={`icon-ns ns-clockGreen w-20 h-20 mr-1`}></i>
            <p className="time">{time}</p>
          </div>
        </div>
      </div>

      <ReactTooltip
        id={`tooltip-type-${id}`}
        style={{
          zIndex: 99,
          padding: 0,
          backgroundColor: "#eaeded",
          color: "#333",
          borderRadius: 12,
          background: "#fff",
          boxShadow: "0px 4px 11px 3px rgba(0, 0, 0, 0.22)",
          fontFamily: "DM Sans",
          fontSize: "14px",
          fontWeight: 500,
        }}
        openOnClick
        closeEvents={{
          mouseleave: true,
        }}
        place="right"
        clickable
        opacity={1}
        content={<TooltipRemark title={title} text={remark} />}
      />
    </div>
  );
};

export default BasicPlayer;
