import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import moment from "moment";
import { useDataStore, usePlayerStore } from "../../hooks/useNavStore";
import RecentList from "../RecentList/RecentList";
import Modal from "../Modal/Modal";
import Featured from "../Featured/Featured";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import BigImageGreen from "../BigImageGreen/BigImageGreen";
import ImageTop from "../ImageTop/ImageTop";
import { v4 as uuidv4 } from "uuid";
import useNew from "../../hooks/useNew";
import { breakpointsConfig } from "../../helper/const";

const FeaturedCarousel = ({
  data,
  headline = "Featured",
  type = "featured",
  isNewProp = false,
}) => {
  const [show, setShow] = useState(false);
  const [list, setList] = useState([]);
  const [activeTab, setActiveTab] = useState("today");

  // console.log(isNew, 'is new')
  const [title, setTitle] = useState(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const swiperRef = useRef(null);
  const carouselContainerRef = useRef(null);

  const [autoplayDisabled, setAutoplayDisabled] = useState(false);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth); // Track just the width

  const { setCurrentAudio } = usePlayerStore();
  const { isNewspool, setAct, emailAddress } = useDataStore();

  const [newList, setNewList] = useState(data);

  const closeModal = () => {
    setShow(false);
    // setCurrentAudio(null);
  };

  const handlePrev = () => {
    if (swiperRef.current) swiperRef.current.slidePrev();

    if (swiperRef.current.autoplay) {
      swiperRef.current.autoplay.start();
    }
  };

  const handleNext = () => {
    if (swiperRef.current) swiperRef.current.slideNext();

    if (swiperRef.current.autoplay) {
      swiperRef.current.autoplay.start();
    }
  };

  const show5 = false;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (swiperRef.current?.autoplay && entry.isIntersecting) {
          if (!show5) {
            swiperRef.current.autoplay.start();
          }
        } else if (swiperRef.current?.autoplay) {
          swiperRef.current.autoplay.stop();
        }
      },
      { threshold: 0.5 }
    );

    if (carouselContainerRef.current) {
      observer.observe(carouselContainerRef.current);
    }

    return () => {
      if (carouselContainerRef.current) {
        observer.unobserve(carouselContainerRef.current);
      }
    };
  }, []);

  const handleMouseEnter = () => {
    if (swiperRef.current?.autoplay) {
      swiperRef.current.autoplay.stop();
    }
  };

  const handleMouseLeave = () => {
    if (swiperRef.current?.autoplay) {
      if (!show5) {
        swiperRef.current.autoplay.start();
      }
    }
  };

  let lastGroupText = null;

  // Define component map based on type
  const componentMap = {
    top_img: ImageTop,
    green: BigImageGreen,
    featured: Featured,
  };

  const SelectedComponent = componentMap[type] || Featured;

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth); // Update the width on resize
    };

    // Attach the resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const showArrow = !show5 || screenWidth < 1600;

  let displayedHeadline = headline;

  const isAktuelle = headline === "Featured";

  const [expanded, setExpanded] = useState(true);

  if (isAktuelle) {
    displayedHeadline = "Aktuelles Angebot";
  }

  const todayHandler = useCallback((tab) => {
    setActiveTab(tab);
  }, []);

  const expandedHandler = useCallback(() => {
    setExpanded((exp) => !exp);
  }, []);

  const filteredList = useMemo(() => {
    if (!isAktuelle) return data; // Return full data when not "Aktuelle"
    return data.filter((e) => e.flag === activeTab);
  }, [data, activeTab, isAktuelle]);

  const counter = useRef(0);

  useEffect(() => {
    if (data.length > 0 && isNewspool && isAktuelle && counter.current === 0) {
      setShow(true);
      setTitle(displayedHeadline);
      counter.current = 1;
    }
  }, [isAktuelle, isNewspool, data]);

  useEffect(() => {
    if (swiperRef.current.autoplay) {
      if (!autoplayDisabled) {
        swiperRef.current.autoplay.start();
      } else {
        swiperRef.current.autoplay.stop();
      }
    }
  }, [autoplayDisabled]);

  const ridLocal = localStorage.getItem("rid") || null;
  const [RID, setRID] = useState(ridLocal);

  const activityWs = (type) => {
    let action = "activity";
    let data = {
      action: action,
      data: {
        rId: RID,
        newsType: "ASP1",
        email: emailAddress,
        type,
      },
    };
    // sendMessage(JSON.stringify(data));
    setAct(JSON.stringify(data));
  };

  const adjustDisplayData = (data, slidesPerGroup) => {
    const remainder = data.length % slidesPerGroup;
    if (remainder === 0) return data; // No need to adjust

    const itemsToAdd = slidesPerGroup - remainder; // Calculate how many more items are needed

    return [...data, ...data.slice(0, itemsToAdd)]; // Duplicate the minimum number of items
  };

  useEffect(() => {
    if (!data || data.length === 0) return;

    let slidesPerGroup = 1;
    Object.keys(breakpointsConfig).forEach((bp) => {
      if (screenWidth >= bp) {
        slidesPerGroup = breakpointsConfig[bp].slidesPerGroup;
      }
    });
    setNewList(adjustDisplayData(data, slidesPerGroup)); // Ensure correct length
  }, [screenWidth, data]);

  return (
    <div className="carousel">
      <div className="flex items-center justify-between mb-2">
        {/* <h1 className="header no_border">{headline}</h1> */}
        <button
          className="header "
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setList(data);
              setShow(true);
              setTitle(displayedHeadline);
            }
          }}
          aria-label={displayedHeadline}
        >
          {displayedHeadline}
        </button>

        <div
          className="flex items-center"
          onMouseEnter={() => setAutoplayDisabled(true)}
          onMouseLeave={() => setAutoplayDisabled(false)}
        >
          <button
            className="header multi_img mr-2 cursor-pointer"
            onClick={() => {
              setList(data);
              setShow(true);
              setTitle(displayedHeadline);

              setAutoplayDisabled(false);

              activityWs(`More: ${displayedHeadline}`);
            }}
            aria-label="mehr"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setList(data);
                setShow(true);
                setTitle(displayedHeadline);

                activityWs(`More: ${displayedHeadline}`);
              }
            }}
          >
            mehr...
          </button>

          {showArrow && (
            <>
              <button
                tabIndex={-1}
                onClick={handlePrev}
                className="swiper-button-prev cursor-pointer"
              >
                <i className="icon-ns ns-arrowLeftEnabled w-6 h-6"></i>
              </button>
              <button
                tabIndex={-1}
                onClick={handleNext}
                className="swiper-button-next cursor-pointer"
              >
                <i className="icon-ns ns-arrowRightEnabled w-6 h-6"></i>
              </button>
            </>
          )}
        </div>
      </div>

      <div
        className="carousel__container relative"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        ref={carouselContainerRef}
      >
        <Swiper
          loop
          slidesPerView={5}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          onSlideChange={(swiper) => setCurrentSlideIndex(swiper.realIndex)}
          breakpoints={breakpointsConfig}
          modules={[Autoplay, Pagination, Navigation]}
          autoplay={
            !autoplayDisabled
              ? {
                  delay: 4500,
                  disableOnInteraction: true,
                }
              : false // Disable autoplay when autoplayDisabled is true
          }
          spaceBetween={type !== "featured" ? 6 : 0}
          key={newList.length} // Forces re-render when data changes
        >
          {newList.map((e, index) => {
            const date = new Date(e.display_timestamp);
            moment.locale("de");
            const timeOnly = moment(date).format("DD.MM. HH:mm");
            let isLastInGroup =
              index === newList.length - 1 ||
              e.grouptext !== newList[index + 1].grouptext;
            const fileId = e.fileId;
            const showHorizontalText = e.grouptext !== lastGroupText;
            lastGroupText = e.grouptext;
            const isFirstSlideWithoutText =
              !showHorizontalText && currentSlideIndex === index;

            const catId = e.cat_id;
            const filename = e.broadcastAudio;

            const flag = e?.flag || null;

            const newUuid = uuidv4();

            const slidesPerGroup =
              swiperRef.current?.params?.slidesPerGroup || 1;

            // Check if the current slide is the last in the current slider page
            let isLastInSlider =
              (index + 1) % slidesPerGroup === 0 ||
              index === newList.length - 1;
            let isFirstInSlider = index % slidesPerGroup === 0;

            const category = e?.category || null;

            let duration = e.len_string;

            const expDate = e.audio3_presigned_expires;

            return (
              <SwiperSlide key={index}>
                <SelectedComponent
                  groupText={e.grouptext}
                  title={e.title}
                  duration={duration}
                  img={e.image}
                  time={timeOnly}
                  url={e.previewAudioURL}
                  remark={e.remark}
                  isYesterdayOrOlder={false}
                  downloadURL={e.broadcastAudioURL}
                  id={newUuid}
                  showHorizontalText={showHorizontalText}
                  isLastInGroup={isLastInGroup}
                  isFirstSlideWithoutText={isFirstSlideWithoutText}
                  filename={filename}
                  catId={catId}
                  flag={flag}
                  fileId={fileId}
                  isLastInSlider={isLastInSlider}
                  isFirstInSlider={isFirstInSlider}
                  category={displayedHeadline}
                  categoryGroup={category}
                  date={date}
                  expDate={expDate}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>

      {show && (
        <Modal
          overlay
          title={title}
          onClose={closeModal}
          isAktuelle={isAktuelle}
          todayHandler={todayHandler}
          activeTab={activeTab}
          expanded={expanded}
          expandedHandler={expandedHandler}
        >
          <RecentList
            catNameProp={displayedHeadline}
            list={filteredList}
            loading={false}
            key={"2"}
            showDesc={!isAktuelle || expanded}
          />
        </Modal>
      )}
    </div>
  );
};

export default FeaturedCarousel;
