import React, { useEffect, useState } from "react";
import testImg from "../../assets/logo_BLR-01.svg";
import {
  useDataStore,
  useLoginStore,
  usePlayerStore,
} from "../../hooks/useNavStore";

import { Tooltip as ReactTooltip } from "react-tooltip";
import TooltipRemark from "../TooltipRemark/Tooltip";
import { downloadStatistic } from "../../helper/fetchData";
import {
  copyToClipboard,
  getTomorrowDayInGerman,
} from "../../helper/dateRelated";
import moment from "moment";

import ReactDOM from "react-dom";
import Spinner from "../Spinner/Spinner";
import useWebSocket from "react-use-websocket";
import { SOCKET_URL } from "../../helper/const";

const ImageTop = ({
  duration = "00:20",
  time,
  url,
  title,
  img,
  remark,
  downloadURL,
  id,
  style = "",
  filename,
  catId,
  flag,
  fileId,
  category = null,
  date,
  groupText,
  categoryGroup = null,
}) => {
  const {
    urlFile,
    isPlaying,
    currentDuration,
    totalDuration,
    showPlayer,
    playAudio,
    pauseAudio,
    stopAudio,
    currentAudio,
    updatedAudios,
    downloadAudio,
    isLoading,
    setLoadingType,
    loadingType,
  } = usePlayerStore();

  const { tokenZ } = useLoginStore();
  const { emailAddress, setAct } = useDataStore();

  // console.log(date, 'date')

  const givenDate = moment(date);
  const today = moment().startOf("day"); // Removes time part for accurate comparison

  const invalidAvailableAt = givenDate.isBefore(today) && !url;

  const forTom = flag === "tomorrow" ? getTomorrowDayInGerman() : null;
  const [showCopy, setShowCopy] = useState(false);

  const tooltipContainer = document.getElementById("tooltip-container");

  const params = {
    customer_id: tokenZ.userId,
    cat_id: catId,
    file_id: id,
    packagename: filename,
    title: title,
    trigger: "BT",
    user_realm: tokenZ.userId,
  };

  const checkIsPlaying = (id) => {
    if (!currentAudio) return false;

    if (updatedAudios.length > 0) {
      const apt = updatedAudios.find((e) => +e.fileId === +id);

      if (apt?.previewAudioURL === currentAudio?.src) return true;
    } else {
      return false;
    }
  };

  const theUrl = (id) => {
    if (updatedAudios.length > 0) {
      const apt = updatedAudios.find((e) => +e.fileId === +id);

      if (apt) {
        return apt.previewAudioURL;
      } else {
        return url;
      }
    } else {
      return url;
    }
  };

  const ridLocal = localStorage.getItem("rid") || null;
  const [RID, setRID] = useState(ridLocal);

  const activityWs = (type) => {
    let action = "activity";
    let data = {
      action: action,
      data: {
        rId: RID,
        newsType: "ASP1",
        email: emailAddress,
        type,
      },
    };
    setAct(JSON.stringify(data));
  };

  const infoActivity = `${category} ${title}`;

  return (
    <div className={`top_image ${style}`}>
      <div className="flex flex-col justify-between h-full">
        <div className={`top_image_container mb-2 relative`}>
          {forTom && <p className="absolute top-0 flag tomoro">{forTom}</p>}
          {!url && <p className="absolute flag shortly">In Kürze</p>}
          {true && <p className="absolute  flag cat">{categoryGroup}</p>}
          <img
            className={`img_top ${!img ? "no_image" : ""}`}
            src={img || testImg}
            alt="news"
          />
        </div>

        <p className="title line-clamp-2 mb-2">
          {/* {title.substring(title.indexOf(" ") + 1)} */}

          {title.replace(/^\(.*?\)\s*/, "")}
        </p>

        <div
          // id={`tooltip-type-${id}`}
          onClick={() => {
            setShowCopy(id);
            // activityWs(`Read Text: ${infoActivity}`);
          }}
          onMouseLeave={() => setShowCopy(null)}
          className="flex items-center mb-2 h-10"
          data-tooltip-id={`tooltip-type-${id}`}
        >
          <p className="sub_title line-clamp-2 cursor-default">{remark}</p>

          {showCopy === id && (
            <i
              className={`icon-ns flex-shrink-0 ns-copyRectangle w-24 h-24 cursor-pointer`}
              onClick={() => {
                copyToClipboard(title, remark);
                activityWs(`Copy Text: ${infoActivity}`);
              }}
            ></i>
          )}
        </div>

        <div className="flex items-center info">
          {url ? (
            <>
              <div className="w-6 h-full flex items-center justify-end mr-2">
                {+isLoading === +fileId && loadingType === "play" ? (
                  <Spinner />
                ) : (
                  <i
                    className={`icon-ns ${
                      isPlaying &&
                      (currentAudio?.src === url || checkIsPlaying(fileId))
                        ? "ns-stopGreen"
                        : "ns-playGreen"
                    } w-24 h-24  cursor-pointer`}
                    onClick={() => {
                      playAudio(theUrl(fileId), fileId, title);
                      setLoadingType("play");

                      activityWs(
                        `${
                          isPlaying &&
                          (currentAudio?.src === url || checkIsPlaying(fileId))
                            ? `Pause: ${infoActivity}`
                            : `Play: ${infoActivity}`
                        }`
                      );
                    }}
                  ></i>
                )}
              </div>

              <p className="duration_as mr-2">{duration}</p>

              <div className="w-5 h-full flex items-center justify-end mr-auto">
                {+isLoading === +fileId && loadingType === "download" ? (
                  <Spinner />
                ) : (
                  <i
                    className="icon-ns ns-downloadGreen w-20 h-20  cursor-pointer"
                    onClick={() => {
                      downloadAudio(fileId, params, downloadURL);
                      setLoadingType("download");

                      activityWs(`Download: ${infoActivity}`);
                    }}
                  ></i>
                )}
              </div>
            </>
          ) : invalidAvailableAt ? (
            <p className="flex-1 vorr line-clamp-1">Abrufbar in Kürze</p>
          ) : (
            <p className="flex-1 vorr line-clamp-1">Vorr. abrufbar ab:</p>
          )}

          {!invalidAvailableAt && (
            <div className="flex items-center">
              <i className="icon-ns ns-clockGreen w-20 h-20 mr-1"></i>
              <p className="time">{time}</p>
            </div>
          )}
        </div>
      </div>

      {ReactDOM.createPortal(
        <ReactTooltip
          // anchorSelect={`#tooltip-type-${id}`}
          id={`tooltip-type-${id}`}
          style={{
            zIndex: 99,
            padding: 0,
            backgroundColor: "#eaeded",
            color: "#333",
            borderRadius: 12,
            background: "#fff",
            boxShadow: "0px 4px 11px 3px rgba(0, 0, 0, 0.22)",
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
          }}
          openOnClick
          closeEvents={{
            mouseleave: true,
          }}
          place="right"
          // variant="info"
          // className="tooltip-computer"
          clickable
          opacity={1}
          content={<TooltipRemark title={title} text={remark} />}
        />,
        tooltipContainer
      )}
    </div>
  );
};

export default ImageTop;
