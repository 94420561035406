import React from 'react'
import asLogo from '../../assets/as_logo.svg'
import blrLogo from '../../assets/blr_ashop_2.svg'
import rCloud from '../../assets/rCloud.svg'

const Footer = () => {
    return (
        <div className='footer '>

            <div className="px-12 flex flex-col">
                <div className="flex items-end justify-between">
                    {/* left */}
                    <div>

                        <div className="flex items-center mb-2">
                            <div>
                                <img className='footer_img blr_logo' src={blrLogo} alt="blr_logo" />
                                {/* <p className='as_text'>AudioShop</p> */}

                            </div>


                            {/* <div className="hor_line max"></div>

                            <div className='flex flex-col items-center'>
                                <img src={asLogo} alt="audioshop_logo" className='footer_img as_logo' />
                            </div> */}


                        </div>

                        <div className='flex items-center'><span className='copyright mr-1'>© 2025</span> <p>BLR GmbH & Co. KG</p></div>

                        <div className='flex items-center'><i className='icon-ns ns-mapBlack w-14 h-14 mr-1'></i> <p className='parking'>Parkring 2, D-85748 Garching bei München</p></div>

                        



                    </div>
                    {/* right */}
                    <div className='right'>

                        <p className='kontak'>Kontakt zum CvD:</p>

                        <div className="flex items-center justify-between">
                            <div>
                                <p className='info_header'>Email:</p>
                                <a className='info_info' href="mailto:cvd@blr.de">cvd@blr.de</a>
                            </div>

                            <div>
                                <p className='info_header'>Telefon:</p>
                                <a className='info_info' href="tel:+498949994510">089-49994-510</a>
                            </div>
                        </div>



                    </div>
                </div>
                <div className="black_line"></div>

                <div className='flex items-center self-center'>
                    <p className='power'>Powered by Radio.Cloud</p>
                    <div className='hor_line'></div>
                    <img src={rCloud} className='footer_img rc_img' alt="radioCLoud" />
                </div>
            </div>

            <div className="bottom_grad"></div>

        </div>
    )
}

export default Footer
// <div>
//     <p className='text-center'>
//         BLR Audioshop: CvD Kontakt{' '}
//         {' '}
//         
//     </p>


//     

//     <p className='text-center'>Powered by Radio.Cloud</p>

// </div>