import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDataStore, useLoginStore } from "../../hooks/useNavStore";
import { fetchData } from "../../helper/fetchData";
import Spinner from "../Spinner/Spinner";

const examples = [
  {
    lastActivity: "Login",
    networkCode: "BLR",
    pageURL: "https://audioshop.blr.de/",
    isp: "Krick Management GmbH trading as iWelt GmbH + Co. KG",
    userName: "theresa.achatz@funkhaus.com",
    isAdmin: 0,
    email: "test@mail.com",
    city: "Würzburg",
    sourceIp: "82.212.252.162",
    region: "Bavaria",
    deviceInfo: "Windows NT 10.0; Win64; x64",
    lastActivityTime: "2025-02-17 06:17:29",
  },
];

const AdminTable = () => {
  // Get all unique keys from the dataset to use as column headers
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const [sortKey, setSortKey] = useState("customerCity"); // Sorting Key

  const { setUserCount, wsGlobal } = useDataStore();

  const preferredOrder = [
    "customerCity",
    "customerName",
    "userName",
    "region",
    "city",
    "isp",
    "deviceInfo",
    "officeLogin",
    "lastActivityTime",
    "lastActivity",
    // "pageURL",
    // "networkCode",
    // "email",
    // "sourceIp",
  ];

  const excludedKeys = ["pageURL", "networkCode", "email", "sourceIp"];

  const allKeys = [
    ...new Set([...preferredOrder, ...data.flatMap(Object.keys)]),
  ].filter((key) => !excludedKeys.includes(key));

  const { tokenZ } = useLoginStore();

  useEffect(() => {
    const fetchAdminData = () => {
      const url = `https://fra-as-api.radio.cloud/v1/getLastActivity`;
      setLoading(true);
      const params = {
        customer_id: tokenZ.userId,
      };

      fetchData(url, params, tokenZ.access_token)
        .then((data) => {
          setData(data);
          setLoading(false);
          setUserCount(data.length);
        })
        .catch((error) => console.error("Error:", error))
        .finally((data) => setLoading(false));
    };

    fetchAdminData();
  }, []);

  const formatHeader = (str) => {
    if (str === "city") return "ISP City";
    if (str === "region") return "ISP Region";
    if (str === "isp") return "ISP";
    if (str === "deviceInfo") return "OS";
    if (str === "officeLogin") return "Login";
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/^./, (char) => char.toUpperCase());
  };

  useEffect(() => {
    if (wsGlobal) {
      setData((prevData) => {
        const emailExists = prevData.some((e) => e.email === wsGlobal.email);

        if (emailExists) {
          return prevData.map((e) =>
            e.email === wsGlobal.email
              ? {
                  ...e,
                  lastActivity: wsGlobal.lastActivity,
                  ...(wsGlobal.lastActivityTime && {
                    lastActivityTime: wsGlobal.lastActivityTime,
                  }),
                }
              : e
          );
        } else {
          return [...prevData, wsGlobal];
        }
      });
    }
  }, [wsGlobal]);

  useEffect(() => {
    setUserCount(data.length);
  }, [data]);

  if (loading) {
    return (
      <div className="h-full  w-full flex justify-center items-center">
        <Spinner />
      </div>
    );
  }

  const tableData = (row, key, full = false, style = false) => {
    if (style && key === "lastActivity") {
      return row[key].slice(0, 7).toLowerCase().trim();
    }
    if (full) {
      if (key === "isp") return row["sourceIp"];
      if (key === "officeLogin") return "";
      return row[key];
    }
    let icon = "apple";
    if (key === "deviceInfo") {
      if (row[key].includes("Windows")) icon = "windows";
      if (row[key].includes("Linux")) icon = "linux";
      return <i className={`icon-ns ns-${icon} w-14 h-14`}></i>;
    }

    if (key === "officeLogin") {
      if (row[key]) icon = "office365";
      if (!row[key]) icon = "loginCon";
      return <i className={`icon-ns ns-${icon} w-14 h-14`}></i>;
    }

    // let char = 15;
    // if (key === "pageURL") char = 25;
    // if (key === "region") char = 8;
    // if (key === "userName") char = 14;
    // if (key === "city") char = 10;
    // if (key === "lastActivityTime") return row[key];
    // if (key === "email") char = 30;

    return row[key];
  };

  const sortedData = [...data].sort((a, b) => {
    const valA = a[sortKey] || "";
    const valB = b[sortKey] || "";
    return valA.localeCompare(valB);
  });

  const handleSort = (key) => {
    if (isSortable(key)) {
      setSortKey(key);
    }
  };

  const getStyle = (key, isHeader = false) => {
    if (key === "deviceInfo" || key === "officeLogin")
      return "flex items-center";

    if (key === "lastActivityTime") return "font-lato";

    return "";
  };

  const isSortable = (key) => {
    return key === "customerCity" || key === "customerName";
  };

  return (
    <div className="table-container-admin">
      {/* Header Row */}
      <div
        className={`table-row header ${sortedData.length >= 33 ? "m_30" : ""}`}
      >
        {allKeys.map((key) => (
          <div
            key={key}
            className={`table-cell-custom header-cell ${
              isSortable(key)
                ? "cursor-pointer flex items-center justify-between"
                : ""
            }  select-none`}
            onClick={() => handleSort(key)}
          >
            <p>{formatHeader(key)}</p>

            {isSortable(key) && (
              <i className={`icon-ns ns-sortTable w-12 h-12`}></i>
            )}
          </div>
        ))}
      </div>

      {/* Data Rows */}
      <div className="table-data">
        {sortedData.map((row, rowIndex) => (
          <div
            key={rowIndex}
            className={`table-row data ${
              rowIndex % 2 === 0 ? "black" : ""
            } ${tableData(row, "lastActivity", false, true)}`}
          >
            {allKeys.map((key) => (
              <div
                key={key}
                className={`table-cell-custom line-clamp-1 ${getStyle(key)} `}
                title={tableData(row, key, true)}
              >
                {row[key] !== undefined ? tableData(row, key) : "-"}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminTable;
