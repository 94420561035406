import { create } from "zustand";
import { persist } from "zustand/middleware";
import { createJSONStorage } from "zustand/middleware";

import { downloadStatistic, fetchData } from "../helper/fetchData";

var retrievedArrayString = localStorage.getItem("speakerConfig");
var settings = JSON.parse(retrievedArrayString) || {};
const navListFromStorage = JSON.parse(localStorage.getItem("navList")) || [];
const typeList = JSON.parse(localStorage.getItem("typeList")) || [];
const networkName = localStorage.getItem("network") || "";
const computerName = localStorage.getItem("computerName") || "";
const ridLocal = localStorage.getItem("rid") || "";
const isDarkLocal = JSON?.parse(localStorage.getItem("isDark")) || "";
const speakerVolumeLocal =
  JSON?.parse(localStorage.getItem("speakerVolume")) || 0;

export const useNavStore = create((set) => ({
  newstype: settings.newstype || "",
  speakerId: settings.speakerId || "",
  navList: navListFromStorage || [],
  navTitle: "",
  showNav: false,
  isMobile: window.innerWidth < 1024,
  setNewstype: (news) => set({ newstype: news }),
  setSpeakerId: (id) => set({ speakerId: id }),
  setNavList: (nav) => set({ navList: nav }),
  setNavTitle: (title) => set({ navTitle: title }),
  setShowNav: (title) => set({ showNav: title }),
  setIsMobile: (value) => set({ isMobile: value }),
}));

export const useDataStore = create(
  persist(
    (set) => ({
      profilesCount: 0,
      distributionEnd: "",
      clientsCount: 0,
      tokenId: "",
      newWs: { action: "" },
      recWss: { action: "" },
      inProduction: false,
      username: "",
      showReleasedBtn: false,
      profiles: [],
      globalStatus: "",
      showCopyBtn: false,
      showDefectBtn: false,
      filename: "",
      typeListGlobal: [],
      latestHour: "",
      version: "",
      showAct: true,
      newsTypeIsLoading: false,
      network: networkName,
      emailAddress: "",
      yellowId: [],
      recordingsTime: 0,
      netTime: 0,
      globalPcName: computerName,
      discardRecordingFromNavbar: false,
      excludeFollowWss: [],
      showNoteAndOption: false,
      isDark: isDarkLocal,
      isSbDragging: false,
      emptyNews: [],
      rIdGlobal: ridLocal,
      roles: "Producer",
      isUserPage: false,
      currentAnchor: "",
      isRecording: false,
      samplingRate: 48000,
      speakerVolume: speakerVolumeLocal,
      isCollapsed: true,
      isIdle: false,
      currentSb: [],
      searchGlobal: "",
      searchFloating: "",
      textOnly: false,
      show25: false,
      dataIsNew: false,
      isLogin: false,
      isNewspool: null,
      act: null,
      userCount: 0,
      wsGlobal: null,
      setIsLogin: (data) => set({ isLogin: data }),
      setProfilesCount: (count) => set({ profilesCount: count }),
      setDistributionEnd: (end) => set({ distributionEnd: end }),
      setClientsCount: (count) => set({ clientsCount: count }),
      setTokenId: (token) => set({ tokenId: token }),
      setNewWs: (msg) => set({ newWs: msg }),
      setRecWss: (msg) => set({ recWss: msg }),
      setInProduction: (data) => set({ inProduction: data }),
      setUsername: (data) => set({ username: data }),
      setShowReleasedBtn: (data) => set({ showReleasedBtn: data }),
      setGlobalProfiles: (data) => set({ profiles: data }),
      setGlobalStatus: (data) => set({ globalStatus: data }),
      setShowCopyBtn: (data) => set({ showCopyBtn: data }),
      setShowDefectBtn: (data) => set({ showDefectBtn: data }),
      setFilename: (data) => set({ filename: data }),
      setTypeListGlobal: (data) => set({ typeListGlobal: data }),
      setLatestHour: (data) => set({ latestHour: data }),
      setVersion: (data) => set({ version: data }),
      setShowAct: (data) => set({ showAct: data }),
      setNewsTypeIsLoading: (data) => set({ newsTypeIsLoading: data }),
      setNetwork: (data) => set({ network: data }),
      setEmailAddress: (data) => set({ emailAddress: data }),
      setYellowNewsId: (data) => set({ yellowId: data }),
      setRecordingsTime: (data) => set({ recordingsTime: data }),
      setNetTime: (data) => set({ netTime: data }),
      setGlobalPcName: (data) => set({ globalPcName: data }),
      setDiscardRecordingFromNavbar: (data) =>
        set({ discardRecordingFromNavbar: data }),
      setExcludeFollowWss: (newWs) =>
        set((state) => {
          const existingIndex = state.excludeFollowWss.findIndex(
            (ws) => ws.newsItemId === newWs.newsItemId
          );

          if (existingIndex !== -1) {
            // Update the existing object
            return {
              excludeFollowWss: state.excludeFollowWss.map((ws) =>
                ws.newsItemId === newWs.newsItemId ? newWs : ws
              ),
            };
          } else {
            // Add the new object to the array
            return {
              excludeFollowWss: [...state.excludeFollowWss, newWs],
            };
          }
        }),
      setShowNoteAndOption: (data) => set({ showNoteAndOption: data }),
      setIsDark: (data) => set({ isDark: data }),
      setUserPage: (data) => set({ isUserPage: data }),
      setIsSbDragging: (data) => set({ isSbDragging: data }),
      setEmptyNews: (data) => set({ emptyNews: data }),
      setRoles: (data) => set({ roles: data }),
      setRidGlobal: (data) => set({ rIdGlobal: data }),
      setCurrentAnchor: (data) => set({ currentAnchor: data }),
      setIsRecording: (data) => set({ isRecording: data }),
      setSamplingRate: (data) => set({ samplingRate: data }),
      setSpeakerVolume: (data) => set({ speakerVolume: data }),
      setIsCollapsed: (data) => set({ isCollapsed: data }),
      setIsIdle: (data) => set({ isIdle: data }),
      setCurrentSb: (data) => set({ currentSb: data }),
      setSearchGlobal: (data) => set({ searchGlobal: data }),
      setSearchFloating: (data) => set({ searchFloating: data }),
      setTextOnly: (data) => set({ textOnly: data }),
      setShow25: (data) => set({ show25: data }),
      setDataIsNew: (data) => set({ dataIsNew: data }),
      setIsNewspool: (data) => set({ isNewspool: data }),
      setAct: (data) => set({ act: data }),
      setUserCount: (data) => set({ userCount: data }),
      setWsGlobal: (data) => set({ wsGlobal: data }),
    }),
    {
      name: "data-store", // Name of the localStorage key
      partialize: (state) => ({
        emailAddress: state.emailAddress, // ✅ Persist only email
        textOnly: state.textOnly, // ✅ Persist text-only mode
      }),
    }
  )
);

const formatTime = (time) => {
  if (typeof time !== "number" || isNaN(time)) {
    return "00:00.0";
  }

  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  const milliseconds = Math.floor((time % 1) * 10); // Get only one decimal place for milliseconds
  return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
    2,
    "0"
  )}.${milliseconds}`;
};

export const useLoginStore = create(
  persist(
    (set) => ({
      tokenZ: null,
      usernameGlobal: "",
      setTokenGlobal: (news) => set({ tokenZ: news }),
      setUsername: (news) => set({ usernameGlobal: news }),
    }),
    {
      name: "login-storage", // Unique name for the storage key
      storage: createJSONStorage(() => localStorage), // ✅ Updated to use `storage`
    }
  )
);
export const usePlayerStore = create((set, get) => ({
  currentAudio: null, // Current audio instance
  urlFileG: "",
  isPlaying: false,
  currentDuration: "00:00.0",
  showPlayer: false,
  totalDuration: "00:00.0",
  isLoading: false,
  retryCount: 0,
  audioIsNotFound: false,
  currentFileId: null,
  updatedAudios: [],
  wsReady: false,
  currentTitle: "",
  loadingType: "",

  setUrlFile: (url) => set({ urlFileG: url }),
  setIsPlaying: (status) => set({ isPlaying: status }),
  setShowPlayer: (status) => set({ showPlayer: status }),
  setCurrentAudio: (audio) => set({ currentAudio: audio }),
  setCurrentTitle: (audio) => set({ currentTitle: audio }),
  setLoadingType: (audio) => set({ loadingType: audio }),
  setCurrentDuration: (duration) => set({ currentDuration: duration }),
  setTotalDuration: (duration) => set({ totalDuration: duration }),
  setIsLoading: (status) => set({ isLoading: status }),
  setRetryCount: (count) => set({ retryCount: count }),
  setAudioIsNotFound: (status) => set({ audioIsNotFound: status }),
  setCurrentFileId: (id) => set({ currentFileId: id }),
  setWsReady: (ws) => set({ wsReady: ws }),

  handleAudioError: async (fileId, type = "audio") => {
    const { tokenZ } = useLoginStore.getState();
    const {
      retryCount,
      setRetryCount,
      setIsLoading,
      setAudioIsNotFound,
      currentFileId,
      setCurrentAudio,
    } = get();
    // If the currentFileId no longer matches the fileId passed here, reset and exit
    if (currentFileId !== fileId && type !== "download") {
      set({
        retryCount: 0,
        audioIsNotFound: false,
        isLoading: false,
      });
      console.warn("FileId mismatch detected. Resetting states.");
      return;
    }

    console.log(retryCount, "retu");

    // If we've already retried once, set audio as not found
    if (retryCount > 1 && type !== "download") {
      console.error("Audio not found after one retry");
      setAudioIsNotFound(true);
      return;
    }

    if (!tokenZ) {
      console.error("Token is not available. Cannot make API call.");
      return;
    }

    setIsLoading(fileId);
    const url = `https://drq39eb793.execute-api.eu-central-1.amazonaws.com/v1/updateAudioURL`;
    const params = { fileId };

    try {
      const response = await fetchData(url, params, tokenZ.access_token);
      console.log("API call successful:", response);

      // Increment the retry count before attempting to play again
      // setRetryCount((prev) => prev + 1); // Use functional update
      setRetryCount((prev) => prev + 1);

      // Attempt to play the new URL returned by the API
      if (response) {
        if (!response.previewAudioURL) {
          return;
        }

        set((state) => {
          const existingIndex = state.updatedAudios.findIndex(
            (audio) => audio.fileId === response.fileId
          );

          if (existingIndex !== -1) {
            // Update the existing object
            const updatedAudios = [...state.updatedAudios];
            updatedAudios[existingIndex] = response;
            return { updatedAudios };
          } else {
            // Add the new response
            return { updatedAudios: [...state.updatedAudios, response] };
          }
        });

        if (type === "audio") {
          get().playAudio(response.previewAudioURL, fileId);
        } else {
          return response.broadcastAudioURL;
        }
      } else {
        console.error("No previewAudioURL returned from the API");
        setAudioIsNotFound(true);
      }
    } catch (error) {
      console.error("API call failed:", error);
      setAudioIsNotFound(true);
    } finally {
      setIsLoading(false);
    }
  },

  playAudio: (url, fileId, title) => {
    if (!url) return;

    console.log(title, "<<<title");

    const {
      currentAudio,
      isPlaying,
      setCurrentAudio,
      setIsPlaying,
      setUrlFile,
      setShowPlayer,
      setTotalDuration,
      setCurrentFileId,
      setRetryCount,
      setAudioIsNotFound,
      setIsLoading,
      setWsReady,
      setCurrentTitle,
    } = get();

    setIsLoading(fileId);

    if (title) {
      setCurrentTitle(title);
    }

    // Reset error-related states whenever we start playing a new audio
    setRetryCount(0);
    setAudioIsNotFound(false);
    setCurrentFileId(fileId);

    // Check if the same URL is already playing
    if (url === currentAudio?.src && currentAudio) {
      setIsLoading(false);
      if (isPlaying) {
        currentAudio.pause();
        setIsPlaying(false);
      } else {
        currentAudio.play();
        setIsPlaying(true);
      }
      return;
    }

    setWsReady(false);

    // If a different URL is provided, stop any current audio
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      currentAudio.removeEventListener("timeupdate", get().updateDuration);
    }

    // Create a new audio instance with the new URL
    const audio = new Audio(url);

    audio.crossOrigin = "anonymous";

    audio.addEventListener("loadedmetadata", () => {
      setCurrentAudio(audio); // Store the new audio instance
      setIsLoading(false);

      setUrlFile(url); // Set the URL in the store
      setIsPlaying(true); // Set the player to 'playing' state
      setShowPlayer(true); // Display the player
      setTotalDuration(formatTime(audio.duration));
    });

    // Add event listener for updating the duration
    audio.addEventListener("timeupdate", () => {
      const el = document.getElementById("cur_dur");
      if (el) {
        el.innerText = formatTime(audio.currentTime);
      }
    });

    audio.addEventListener("error", () => {
      const {
        handleAudioError,
        currentFileId,
        setRetryCount,
        retryCount,
        audioIsNotFound,
      } = get();

      // If we already know audio is not found, no further attempts
      if (audioIsNotFound) return;

      handleAudioError(currentFileId);
      // console.log(retryCount, "retry count");
      // if (retryCount < 1) {
      //   console.log("Calling handleAudioError...");
      // } else {
      //   console.error("Max retries reached, marking audio as not found.");
      //   setAudioIsNotFound(true);
      // }
    });

    // Start playing the new audio
    audio.play();
  },

  pauseAudio: () => {
    const { currentAudio, setIsPlaying } = get();
    if (currentAudio) {
      currentAudio.pause();
      setIsPlaying(false);
    }
  },

  stopAudio: () => {
    const { currentAudio, setIsPlaying, setCurrentDuration, setShowPlayer } =
      get();
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      setIsPlaying(false);
      setCurrentDuration("00:00.0");
      setShowPlayer(false);
    }
  },

  updateDuration: () => {
    const { currentAudio, setCurrentDuration } = get();
    if (currentAudio) {
      setCurrentDuration(formatTime(currentAudio.currentTime));
    }
  },

  downloadAudio: async (fileId, params, downloadURL, expDate = null) => {
    let theD = downloadURL;

    if (!downloadURL) return;

    const { updatedAudios, handleAudioError } = get();
    const { tokenZ } = useLoginStore.getState();

    console.log(expDate, "EXPIREDD");

    if (updatedAudios.length > 0) {
      const apt = updatedAudios.find((e) => +e.fileId === +fileId);

      if (apt) {
        theD = apt.broadcastAudioURL;

        const link = document.createElement("a");
        link.href = theD;
        link.download = params?.title || "audio";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Remove the element after download

        // Send statistics
        downloadStatistic(params, tokenZ.access_token);

        return;
      } else {
        theD = downloadURL;
      }
    } else {
      theD = downloadURL;
    }

    console.log(theD, "link");

    const expirationTime = expDate ? new Date(expDate).getTime() : null;
    const currentTime = new Date().getTime();

    try {
      if (!expirationTime || currentTime < expirationTime) {
        // URL is valid, proceed with download
        const link = document.createElement("a");
        link.href = theD;
        link.download = params?.title || "audio";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Send statistics
        downloadStatistic(params, tokenZ.access_token);
      } else {
        const newDownloadUrl = await handleAudioError(fileId, "download");

        console.log(newDownloadUrl);

        const link = document.createElement("a");
        link.href = newDownloadUrl;
        link.download = params?.title || "audio";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Send statistics
        downloadStatistic(params, tokenZ.access_token);
      }
    } catch (error) {
      console.error("Error checking download URL:", error);
    }
  },
}));
