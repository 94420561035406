import React, { useRef, useState } from "react";
import {
  useDataStore,
  useLoginStore,
  usePlayerStore,
} from "../../hooks/useNavStore"; // Zustand store for audio control
import testImg from "../../assets/logo_BLR-01.svg";
import { Tooltip as ReactTooltip } from "react-tooltip";
import TooltipRemark from "../TooltipRemark/Tooltip";
import { downloadStatistic } from "../../helper/fetchData";
import { copyToClipboard } from "../../helper/dateRelated";
import Spinner from "../Spinner/Spinner";
import useWebSocket from "react-use-websocket";
import { SOCKET_URL } from "../../helper/const";

const BasicPlayerImg = ({
  url,
  title,
  time,
  duration,
  img,
  remark,
  isYesterdayOrOlder,
  downloadURL,
  id,
  catId,
  filename,
  catNameProp,
  expDate = "",
}) => {
  const {
    urlFile,
    isPlaying,
    currentDuration,
    totalDuration,
    showPlayer,
    playAudio,
    pauseAudio,
    stopAudio,
    currentAudio,
    downloadAudio,
    isLoading,
    updatedAudios,
    setLoadingType,
    loadingType,
  } = usePlayerStore();

  const { dataIsNew, setDataIsNew, emailAddress, setAct } = useDataStore();
  const elementRef = useRef(null);
  const timeoutRef = useRef(null);

  const [showCopy, setShowCopy] = useState(false);

  const { tokenZ } = useLoginStore();

  const params = {
    customer_id: tokenZ.userId,
    // "reference": "user",
    cat_id: catId,
    file_id: id,
    packagename: filename,
    title: title,
    trigger: "BR",
    user_realm: tokenZ.userId,
  };

  const theUrl = (id) => {
    if (updatedAudios.length > 0) {
      const apt = updatedAudios.find((e) => +e.fileId === +id);

      if (apt) {
        return apt.previewAudioURL;
      } else {
        return url;
      }
    } else {
      return url;
    }
  };

  const checkIsPlaying = (id) => {
    if (!currentAudio) return false;

    if (updatedAudios.length > 0) {
      const apt = updatedAudios.find((e) => +e.fileId === +id);

      if (apt?.previewAudioURL === currentAudio?.src) return true;
    } else {
      return false;
    }
  };

  const ridLocal = localStorage.getItem("rid") || null;
  const [RID, setRID] = useState(ridLocal);

  const activityWs = (type) => {
    let action = "activity";
    let data = {
      action: action,
      data: {
        rId: RID,
        newsType: "ASP1",
        email: emailAddress,
        type,
      },
    };
    setAct(JSON.stringify(data));
  };

  const infoActivity = `${catNameProp} ${title}`;

  return (
    <div
      className={`basic_img ${
        dataIsNew === id ? "blue-bor" : ""
      }  grid grid-cols-12 h-full relative`}
      ref={elementRef}
    >
      {/* {(dataIsNew === id) && <p onMouseEnter={() => setDataIsNew(null)} className='absolute new_blue'>NEW</p>} */}

      <div className="col-span-8 left h-full flex flex-col justify-between">
        <p className="title line-clamp-2">{title.replace(/^\(.*?\)\s*/, "")}</p>
        <div
          // title={remark}

          onClick={() => {
            setShowCopy(id);
            // activityWs(`Read Text: ${infoActivity}`);
          }}
          onMouseLeave={() => setShowCopy(null)}
          className=" mb-2 h-10 flex items-center"
          id={`tooltip-type-${id}`}
        >
          <p className="sub_title cursor-default line-clamp-2">{remark}</p>

          {showCopy === id && (
            <i
              className={`icon-ns flex-shrink-0 ns-copyRectangle w-24 h-24 cursor-pointer`}
              onClick={() => {
                copyToClipboard(title, remark);
                activityWs(`Copy Text: ${infoActivity}`);
              }}
            ></i>
          )}
        </div>

        <div className="flex items-center info">
          <div className="w-6 h-full flex items-center mr-2">
            {+isLoading === +id && loadingType === "play" ? (
              <Spinner />
            ) : (
              <i
                className={`icon-ns ${
                  isPlaying && (currentAudio?.src === url || checkIsPlaying(id))
                    ? "ns-stopGreen"
                    : "ns-playGreen"
                } w-24 h-24  flex-shrink-0 cursor-pointer`}
                onClick={() => {
                  playAudio(theUrl(id), id, title);

                  setLoadingType("play");

                  activityWs(
                    `${
                      isPlaying &&
                      (currentAudio?.src === url || checkIsPlaying(id))
                        ? `Pause: ${infoActivity}`
                        : `Play: ${infoActivity}`
                    }`
                  );
                }}
              ></i>
            )}
          </div>

          <p className="duration_as mr-2">{duration}</p>

          {+isLoading === +id && loadingType === "download" ? (
            <div className="w-5 h-5 flex-1 flex">
              <Spinner size="4" />
            </div>
          ) : (
            <i
              className="icon-ns ns-downloadGreen w-20 h-20 mr-auto cursor-pointer"
              onClick={() => {
                downloadAudio(id, params, downloadURL, expDate);
                setLoadingType("download");

                activityWs(`Download: ${infoActivity}`);
              }}
            ></i>
          )}

          <div className="flex items-center">
            <i
              className={`icon-ns ns-${
                isYesterdayOrOlder ? "calendar" : "clock"
              }Green w-20 h-20 mr-1`}
            ></i>
            <p className="time">{time}</p>
          </div>
        </div>
      </div>

      <div className="right col-span-4">
        <div className={`img_container`}>
          <img
            className={`${!img ? "no_image" : ""}`}
            src={img || testImg}
            alt="Audio Thumbnail"
          />
        </div>
      </div>

      <ReactTooltip
        anchorSelect={`#tooltip-type-${id}`}
        style={{
          zIndex: 99,
          padding: 0,
          backgroundColor: "#eaeded",
          color: "#333",
          borderRadius: 12,
          background: "#fff",
          boxShadow: "0px 4px 11px 3px rgba(0, 0, 0, 0.22)",
          fontFamily: "DM Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
        }}
        openOnClick
        closeEvents={{
          mouseleave: true,
        }}
        place="right"
        // variant="info"
        // className="tooltip-computer"
        clickable
        opacity={1}
        content={<TooltipRemark title={title} text={remark} />}
      />
    </div>
  );
};

export default BasicPlayerImg;
