import moment from "moment";
import momentTz from 'moment-timezone';

export function formatDateByCountry(countryCode, date) {
    let formattedDate;

    if (countryCode === 'DE') {
        formattedDate = moment(date).format('DD.MM.YY');
    } else if (countryCode === 'EN') {
        formattedDate = moment(date).format('MM/DD/YY');
    } else {
        formattedDate = moment(date).format('YYYY-MM-DD'); // Default format
    }

    return formattedDate;
}

export function formatToUSDate24Hour(dateString) {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false  // Disable AM/PM for 24-hour format
    });
}

export const formatTimeOnly = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    });
};


// export function getThreeTimes(tz = "DE") {

//     let now = momentTz().tz('Europe/Berlin'); 

//     if (tz === "sky" || tz === "nna") now = momentTz().tz('America/New_York');
    
//     const roundedTime = now.minutes(0).seconds(0); // Round down to nearest hour
//     const times = [];

//     // Push the current time and the next two hours into the array
//     for (let i = 0; i < 7; i++) {
//         const currentTime = roundedTime.clone().add(i, 'hours').format('HH:mm');
//         times.push(currentTime);
//     }

//     return times;
// }

export function getThreeTimes(tz = "DE", timestamp = null) {
    let now = momentTz().tz('Europe/Berlin')
    if (tz === "sky" || tz === "nna") {
        now = momentTz().tz('America/New_York')   
    }

    const roundedTime = now.clone().minutes(0).seconds(0); // Ensure rounded to nearest hour
    const times = [];

    if (timestamp) {
        let startTime = momentTz(timestamp).minutes(0).seconds(0);
        
        // Check if timestamp is from previous day or earlier
        if (startTime.isBefore(now.clone().startOf('day'))) {
            for (let i = 1; i < 8; i++) {
                const currentTime = roundedTime.clone().add(i, 'hours').minutes(0).seconds(0).format('HH:mm');
                times.push(currentTime);
            }
            return times;
        }
        
        const currentHour = now.hour();
        const maxHour = currentHour + 8;
        const startHour = startTime.hour();

        // Generate times until 8 hours max from the current hour
        for (let i = 0; i < 7; i++) {
            const currentTime = startTime.clone().add(i, 'hours').minutes(0).seconds(0).format('HH:mm');
            const currentHourToCheck = startHour + i;

            if (currentHourToCheck <= maxHour) {
                times.push(currentTime);
            } else {
                break; // Exit the loop if it exceeds the maxHour
            }
        }
        return times;
    }

    if (tz === "rca") {
        let hourCount = 0;
        let currentTime = roundedTime.clone();

        while (hourCount < 10) {
            const hour = currentTime.hour();
            if (hour >= 21 || hour < 5) {
                // Skip to 05:00 if in the range 21:00 - 04:00
                currentTime.add(24 - hour + 5, 'hours'); // Add hours to reach 05:00
                currentTime.minutes(0).seconds(0); // Ensure minutes and seconds are set to 0
                continue;
            } else {
                times.push(currentTime.format('HH:mm'));
                hourCount++;
            }
            currentTime = currentTime.add(1, 'hours').minutes(0).seconds(0); // Ensure minutes and seconds are set to 0
        }
    } else {
        // Push the current time and the next six hours into the array for other time zones
        for (let i = 0; i < 7; i++) {
            const currentTime = roundedTime.clone().add(i, 'hours').minutes(0).seconds(0).format('HH:mm');
            times.push(currentTime);
        }
    }

    return times;
}

export async function copyToClipboard(headline, text) {
    try {
        const combinedText = `${headline}\n\n${text}`;
        await navigator.clipboard.writeText(combinedText);
    } catch (err) {
        console.error('Failed to copy text:', err);
    }
}


export function getTomorrowDayInGerman() {
    const daysInGerman = [
        "Sonntag",  // Sunday
        "Montag",   // Monday
        "Dienstag", // Tuesday
        "Mittwoch", // Wednesday
        "Donnerstag", // Thursday
        "Freitag",  // Friday
        "Samstag"   // Saturday
    ];

    const today = new Date();
    const tomorrow = (today.getDay() + 1) % 7;

    return `für ${daysInGerman[tomorrow]}`;
}




