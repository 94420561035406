import {
  MsalProvider,
  AuthenticatedTemplate,
  useMsal,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { useDataStore, useLoginStore } from "./hooks/useNavStore";
import Login from "./pages/Login";
import Homepage from "./pages/Homepage";

const RedirectLogger = () => {
  return <div></div>; // Optional UI
};

const MainContent = () => {
  const { instance } = useMsal();
  const { tokenZ } = useLoginStore();
  const activeAccount = instance.getActiveAccount();

  return (
    <div className="App">
      <AuthenticatedTemplate>
        {activeAccount ? (
          <Router>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/redirect" element={<RedirectLogger />} />
            </Routes>
          </Router>
        ) : null}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {tokenZ ? (
          <Router>
            <Routes>
              <Route path="/" element={<Homepage />} />
            </Routes>
          </Router>
        ) : (
          <Login />
        )}
      </UnauthenticatedTemplate>
    </div>
  );
};

const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <MainContent />
    </MsalProvider>
  );
};

export default App;
