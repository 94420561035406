import React, { useState, useRef, useCallback } from "react";
import BasicPlayer from "../BasicPlayer/BasicPlayer";
import { v4 as uuidv4 } from "uuid";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import moment from "moment";
import Modal from "../Modal/Modal";
import RecentList from "../RecentList/RecentList";
import { useDataStore, usePlayerStore } from "../../hooks/useNavStore";
import useNew from "../../hooks/useNew";

const BasicView = ({ data, count = 1, loading }) => {
  const { setCurrentAudio } = usePlayerStore();
  const { textOnly, setAct, emailAddress } = useDataStore();

  // const [showNew, setShowNew] = useState(true);
  // const [isNew, setIsNew] = useState(false)
  const containerRef = useRef(null); // Reference to the component
  const timeoutRef = useRef(null); // Reference for the timeout to clear it if necessary

  const blockRows = data?.block_rows || 1;
  const columnCount = textOnly && blockRows === 1 ? 6 : data?.column_count;

  const [list, setList] = useState([]);
  const [title, setTitle] = useState(null);
  const [show, setShow] = useState(false);

  const closeModal = useCallback(() => {
    setShow(false);
    // setCurrentAudio(null);
    // Your close modal logic here
  }, []);

  const multiplier = textOnly && blockRows === 1 ? 6 : columnCount * blockRows;

  // console.log(show, 'show')

  const ridLocal = localStorage.getItem("rid") || null;
  const [RID, setRID] = useState(ridLocal);

  const activityWs = (type) => {
    let action = "activity";
    let data = {
      action: action,
      data: {
        rId: RID,
        newsType: "ASP1",
        email: emailAddress,
        type,
      },
    };
    // sendMessage(JSON.stringify(data));
    setAct(JSON.stringify(data));
  };

  return (
    <div className="dpa_audio">
      <div className="flex items-center justify-between mb-2 border-bttm-eee">
        <button
          className="header "
          aria-label={data?.category_name}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setList(data.data);
              setShow(true);
              setTitle(data?.category_name);
            }
          }}
        >
          {data?.category_name}
        </button>
        {/* <div className="flex items-center">
                </div> */}
        <button
          className="header multi_img cursor-pointer"
          onClick={() => {
            setList(data.data);
            setShow(true);
            setTitle(data?.category_name);
            activityWs(`More: ${data?.category_name}`);
          }}
          aria-label="mehr"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setList(data.data);
              setShow(true);
              setTitle(data?.category_name);
              activityWs(`More: ${data?.category_name}`);
            }
          }}
        >
          mehr...
        </button>
      </div>

      <div
        className="dpa_container"
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
        }}
      >
        {loading &&
          [1, 2, 3, 4].map((e) => (
            <Skeleton key={e} height={"100%"} width={"100%"} count={6} />
          ))}

        {data?.data?.length > 0 &&
          data.data.slice(0, multiplier).map((e, idx) => {
            const newUuid = uuidv4();
            const date = e.display_timestamp;
            moment.locale("de"); // Set the locale to German
            const timeOnly = moment(date).format("DD.MM. HH:mm");
            const isYesterdayOrOlder = moment(e.display_timestamp).isBefore(
              moment().startOf("day").subtract(1, "days")
            );

            const duration = e.len_string;
            const title = e.title;
            const id = e.fileId;

            const catId = e.cat_id;
            const filename = e.broadcastAudio;
            const expDate = e.audio3_presigned_expires;

            return (
              <BasicPlayer
                duration={duration}
                time={timeOnly}
                title={title}
                key={id}
                id={id}
                catId={catId}
                remark={e.remark}
                url={e.previewAudioURL}
                isYesterdayOrOlder={isYesterdayOrOlder}
                downloadURL={e.broadcastAudioURL}
                filename={filename}
                catName={data?.category_name}
                expDate={expDate}
              />
            );
          })}
      </div>

      {show && (
        <Modal overlay title={title} onClose={closeModal}>
          <RecentList
            catNameProp={data?.category_name}
            list={list}
            loading={false}
            key={"2"}
          />
        </Modal>
      )}
    </div>
  );
};

export default BasicView;
