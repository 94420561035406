import React, { useEffect, useState, memo } from "react";
import { useDataStore, useNavStore } from "../../hooks/useNavStore";
import moment from "moment";

const Modal = memo(
  ({
    children,
    onClose,
    title,
    icon,
    showHeader = true,
    overlay = false,
    zIndex = 15,
    isTop = false,
    iconSize = 24,
    hideOverlay = false,
    isAktuelle = false,
    todayHandler = () => {},
    expandedHandler = () => {},
    activeTab = false,
    expanded = false,
    subHeader = null,
  }) => {
    const isDark = useDataStore((state) => state.isDark);
    const isMobile = false;

    const todayDate = moment().format("DD.MM.YYYY");

    useEffect(() => {
      const handleKeyDown = (event) => {
        if (event.key === "Escape") {
          console.log("escape!");
          onClose();
        }
      };

      document.addEventListener("keydown", handleKeyDown);

      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, [onClose]);

    const overlayBlur = overlay
      ? {
          backgroundColor: isDark
            ? "rgba(27, 27, 27, 0.6)"
            : "rgba(255, 255, 255, 0.8)",
          backdropFilter: "blur(2px)",
          zIndex,
        }
      : { zIndex };

    return (
      <div
        className={`${!hideOverlay ? "modal-overlay" : ""}`}
        onClick={onClose}
        style={overlayBlur}
      >
        <div
          className={`modal-content thin-scrollbar ${isTop ? "top" : ""} ${
            isDark ? "black" : ""
          } ${isMobile ? "mob" : ""}`}
          onClick={(e) => e.stopPropagation()}
        >
          {showHeader && (
            <div
              className={`flex items-center justify-between  p-4 modal-header w-full ${
                subHeader && "py-1"
              }`}
            >
              <div className="flex items-center">
                {icon && (
                  <i
                    className={`icon-ns ns-${icon} w-${iconSize} h-${iconSize} flex-shrink-0 cursor-pointer mr-2`}
                  ></i>
                )}
                <p className="forgotten-bold gradient-text">{title}</p>

                {isAktuelle && (
                  <div className="switch-container select-none">
                    <div className="switch-button">
                      <div
                        className={`switch-option ${
                          activeTab === "today" ? "active" : ""
                        }`}
                        onClick={() => todayHandler("today")}
                      >
                        Heute
                      </div>
                      <div
                        className={`switch-option ${
                          activeTab === "tomorrow" ? "active" : ""
                        }`}
                        onClick={() => todayHandler("tomorrow")}
                      >
                        Morgen (Vorschau)
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {isAktuelle && (
                <p className="date_aktelle">
                  {activeTab === "today"
                    ? todayDate
                    : moment().add(1, "days").format("DD.MM.YYYY")}
                </p>
              )}

              <div className="flex items-center">
                {isAktuelle && (
                  <div
                    className={` text_only ${
                      !expanded ? "active" : ""
                    } flex-shrink-0 mr-2`}
                    onClick={expandedHandler}
                  >
                    Alle Beschreibungen anzeigen
                  </div>
                )}

                {subHeader && (
                  <span className="mr-2 subheader">{subHeader}</span>
                )}
                <i
                  onClick={onClose}
                  className={`icon-ns ns-closeGray w-24 h-24 flex-shrink-0 cursor-pointer mr-1`}
                ></i>
              </div>
            </div>
          )}
          {children}
        </div>
      </div>
    );
  }
);

export default Modal;
