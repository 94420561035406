import React, { useEffect, useState } from "react";
import SearchAudioshop from "../SearchAudioshop/SearchAudioshop";
import { fetchData, refreshToken } from "../../helper/fetchData";
import {
  useDataStore,
  useLoginStore,
  usePlayerStore,
} from "../../hooks/useNavStore";
import { useMsal } from "@azure/msal-react";
import RecentList from "../RecentList/RecentList";
import Modal from "../Modal/Modal";

const Welcome = ({ data, loading = false }) => {
  const [backgroundImageUrl, setBackgroundImageUrl] = useState(null);
  const { tokenZ, setTokenGlobal } = useLoginStore();

  const { textOnly, show25, setShow25, emailAddress, setAct } = useDataStore();

  const hostname = window.location.hostname;

  const isBeta = hostname === "audioshop-beta.blr.de";

  const selectRandomImages = () => {
    const uniqueImages = new Set();
    while (uniqueImages.size < 3 && data.length > uniqueImages.size) {
      const randomIndex = Math.floor(Math.random() * data.length);
      uniqueImages.add(data[randomIndex].image);
    }

    // Only set the background images without the gradient
    const [firstImage, secondImage, thirdImage] = Array.from(uniqueImages);
    const backgroundImages = `
            url(${firstImage}),
            url(${secondImage}),
            url(${thirdImage})
        `;

    setBackgroundImageUrl(backgroundImages);
  };

  useEffect(() => {
    // Initial image selection
    selectRandomImages();

    // Update images every 5 minutes
    const intervalId = setInterval(selectRandomImages, 300000); // 5 minutes in ms

    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, [data]);

  const [loading25, setLoading25] = useState(true);

  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const [show, setShow] = useState(false);

  const { setCurrentAudio } = usePlayerStore();

  const ridLocal = localStorage.getItem("rid") || null;
  const [RID, setRID] = useState(ridLocal);

  const activityWs = (type) => {
    let action = "activity";
    let data = {
      action: action,
      data: {
        rId: RID,
        newsType: "ASP1",
        email: emailAddress,
        type,
      },
    };
    // sendMessage(JSON.stringify(data));
    setAct(JSON.stringify(data));
  };

  return (
    <div className={`welcome ${textOnly ? "hidden" : " "}`}>
      <div
        className="background-layer"
        style={{
          backgroundImage: backgroundImageUrl || "#d3d3d3",
          backgroundSize: "33.33%",
          backgroundPosition: "left, center, right",
          backgroundRepeat: "no-repeat",
        }}
      ></div>

      {/* Main content, unaffected by the gradient overlay */}
      <div className="content">
        <div className="flex justify-between h-full">
          <div
            className="jigo_container flex items-center self-end cursor-pointer ml-2 mb-2"
            onClick={() => {
              setShow25(true);
              activityWs(`More: Die neuesten 25 Meldungen`);
            }}
          >
            <p className="jigo p-2 forgotten">25</p>

            <p
              tabIndex={0}
              className="text"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setShow25(true);
                  activityWs(`More: Die neuesten 25 Meldungen`);
                }
              }}
            >
              Die neuesten 25 Meldungen
            </p>
          </div>

          <div className="w-full flex items-center flex-col mt-[32px]">
            <div className="announcement">
              <p className="as_log">AudioShop</p>

              {/* {
                                isBeta ? (
                                    <div>

                                        <p className='header'>Der neue Audioshop ist nun offiziell in Betrieb. Bitte speichere die offizielle Adresse <span className='link normal'><a href="https://audioshop.blr.de" target='_blank'>https://audioshop.blr.de</a></span></p>
                                        <p className="header">(du nutzt derzeit noch die audioshop-beta Adresse)</p>

                                    </div>
                                ) : (
                                    <p className='header'>Willkommen im neuen Audioshop der BLR. Falls Du dich gerade nicht mit dem neuen Audioshop beschäftigen kannst: <span className='link'><a href="https://audioshop-alt.blr.de" target='_blank'>Hier</a></span> geht es nochmal zurück zum alten Audioshop.</p>
                                )
                            } */}
            </div>

            <div className="w-[50%]">
              <SearchAudioshop loading={loading} />
            </div>
          </div>
          <div className="w-[348px]"></div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
