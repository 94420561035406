import React, { useRef, useState, useEffect } from "react";
import blrLogo from "../../assets/blr_ashop_2.svg";
import SearchWhite from "../SearchWhite/SearchWhite";
import {
  useDataStore,
  useLoginStore,
  usePlayerStore,
} from "../../hooks/useNavStore";
import { secondsToTime } from "../../helper/extractNumber";
import WaveSurfer from "wavesurfer.js";
import Spinner from "../Spinner/Spinner";
import { useMsal } from "@azure/msal-react";
import { fetchData, refreshToken } from "../../helper/fetchData";
import Modal from "../Modal/Modal";
import RecentList from "../RecentList/RecentList";
import { useNavigate } from "react-router-dom";
import AdminTable from "../AdminTable/AdminTable";

const FloatingPlayer = ({ sLoading, hideSearchbar = false }) => {
  const isDark = false;
  const isMobile = false;

  const [wsIsLoading, setWsIsLoading] = useState(false);
  const [audioIsReady, setAudioIsReady] = useState(false);
  const [currentTime, setCurrentTime] = useState("00:00.0");
  const soundRef = useRef(null);

  const navigate = useNavigate();

  const {
    searchGlobal,
    searchFloating,
    setSearchFloating,
    setTextOnly,
    textOnly,
    setShow25,
    emailAddress,
    userCount,
    setUserCount,
  } = useDataStore();

  const { usernameGlobal } = useLoginStore();

  const waveformRef = useRef(null);
  const wsRef = useRef(null);

  const {
    currentAudio,
    setCurrentAudio,
    isPlaying,
    setIsPlaying,
    currentDuration,
    setCurrentDuration,
    showPlayer,
    totalDuration,
    setTotalDuration,
    playAudio,
    setShowPlayer,
    urlFileG,
    setWsReady,
    wsReady,
    currentTitle,
    currentFileId,
  } = usePlayerStore();

  const urlFile = {};

  const seekAudio = () => {
    let currentTime;
    if (wsRef.current) {
      currentTime = wsRef.current.getCurrentTime();
    }
    soundRef.current.currentTime = currentTime;
  };

  useEffect(() => {
    if (!currentAudio) {
      return;
    }

    const waveformElement = document.getElementById("waveform-global");
    if (waveformElement) {
      waveformElement.innerHTML = ""; // Clear the previous waveform
    }

    // if (urlFile.url === 'noAudio') return
    setWsIsLoading(true);

    setAudioIsReady(false);

    let wavesurfer;
    wsRef.current = null;

    let audio = currentAudio;

    audio.crossOrigin = "anonymous";

    soundRef.current = audio;

    audio.addEventListener("ended", () => {
      // isPlayingAudio.current = false;
      // cancelAnimationFrame(animationRef.current);
      setIsPlaying(false);

      setTotalDuration("00:00.0");
    });

    // audio.addEventListener("ended", function() {
    // });

    audio.addEventListener(
      "timeupdate",
      (event) => {
        const currentTime = audio?.currentTime || "";
        if (currentTime) {
          setCurrentTime(secondsToTime(audio.currentTime));
        }
      },
      false
    );

    let background = "Blue";
    switch (urlFile.type) {
      case "meldungen":
        background = "Green";
        break;
      case "AI":
        background = "AI";
        break;
      default:
        break;
    }

    setAudioIsReady(true);
    const pColor = "#00B17E";
    const wColor = "#AEAEAE";

    const element = document.getElementById(`waveform-global`);

    wavesurfer = WaveSurfer.create({
      container: element,
      waveColor: wColor,
      progressColor: pColor,
      url: urlFileG,
      dragToSeek: true,
      width: "100%",
      hideScrollbar: true,
      normalize: true,
      barGap: 1,
      barRadius: 2,
      barWidth: 2,
      height: isMobile ? 25 : 50,
      // backend: "MediaElement",
    });

    const dur = audio.duration;
    //   setDuration(secondsToTime(dur))

    wsRef.current = wavesurfer;
    // if (!wavesurfer) {
    // }

    const sbReg = urlFile?.sbRegion || [];

    wavesurfer.on("ready", () => {
      wavesurfer.setVolume(0);

      // if (audio) {

      // }

      setWsReady(true);

      if (audio && !audio.paused) {
        wavesurfer.play();
        wavesurfer.media.currentTime = audio.currentTime;
      }
    });

    // Update the progress bar as the audio file is loaded
    wavesurfer.on("loading", function (percent) {
      // document.getElementById("loadingText").innerText = `${percent}%`;
      if (percent === 100) {
        // document.getElementById("loadingText").innerText = "";
        setWsIsLoading(false);
      }
    });

    wavesurfer.on("error", (error) => {
      console.error("WaveSurfer error:", error);

      setWsIsLoading(false);
    });

    wavesurfer.on("interaction", (newTime) => {
      seekAudio();
    });

    if (urlFile.start) {
      audio.currentTime = urlFile.start / 1000;
    }

    // setIsPlaying(true);

    // audio.play();

    return () => {
      if (wavesurfer) {
        wavesurfer.destroy();
      }

      if (audio) {
        audio.pause();
        audio = null;
      }
    };
  }, [urlFileG, currentAudio]);

  const togglePlay = () => {
    if (!currentAudio) return;

    playAudio(currentAudio.src, currentFileId, currentTitle);
  };

  useEffect(() => {
    if (!currentAudio) return;

    if (!wsReady) return;

    if (!isPlaying && wsRef.current) {
      wsRef.current.pause();
    }

    // console.log(wsReady, 'wsReady')

    if (isPlaying && wsRef.current) {
      wsRef.current.play();
    }
  }, [isPlaying, currentAudio, wsReady]);

  const theIcon = isPlaying
    ? "pauseRoundedGreen"
    : currentAudio
    ? "playRoundedGreen"
    : "playRoundedDisabled";
  const isDisabled = theIcon === "playRoundedDisabled";

  const { tokenZ, setTokenGlobal } = useLoginStore();

  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowPlayer(true);
      } else {
        setShowPlayer(false);
      }
    };

    // Set directly to window to ensure it works
    window.onscroll = handleScroll;

    // Cleanup function to reset window.onscroll
    return () => {
      window.onscroll = null;
    };
  }, []);

  // function createFileIDArray() {
  //     const array = [];
  //     for (let i = 0; i < 10; i++) {
  //         array.push({ FileID: i });
  //     }
  //     return array;
  // }

  // const result = createFileIDArray();

  const handleLogoutRedirect = async () => {
    setTokenGlobal(null);
    localStorage.removeItem("login-storage");

    refreshToken(tokenZ.refresh_token, tokenZ.userId, "revokeToken").then(
      (data) => {}
    );

    if (activeAccount) {
      try {
        await instance.logoutRedirect({
          account: activeAccount,
          extraQueryParameters: { login_hint: activeAccount.username },
        });
      } catch (error) {
        console.error("Logout failed:", error);
      }
    } else {
      setTokenGlobal(null);
      localStorage.removeItem("login-storage");
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!currentAudio || !wsRef.current) return;

      // if (event.key === ' ') {
      //   event.preventDefault(); // Prevent spacebar from scrolling the page
      //   playAudio(currentAudio.src); // Call the playAudio function
      //   return
      // }

      const step = 5; // Fast forward/rewind step in seconds
      const currentTime = currentAudio.currentTime;

      if (event.key === "ArrowRight") {
        const newTime = Math.min(currentTime + step, currentAudio.duration); // Prevent exceeding duration
        currentAudio.currentTime = newTime;
        wsRef.current.seekTo(newTime / currentAudio.duration); // WaveSurfer seek
      } else if (event.key === "ArrowLeft") {
        const newTime = Math.max(currentTime - step, 0); // Prevent going below 0
        currentAudio.currentTime = newTime;
        wsRef.current.seekTo(newTime / currentAudio.duration); // WaveSurfer seek
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentAudio, wsRef]);

  const hostname = window.location.hostname;
  const isBeta = hostname === "audioshop-beta.blr.de";
  const allowedDomains = ["@blr.de", "@radio.cloud", "@gmc.tv"];

  const [showAdmin, setShowAdmin] = useState(false);

  const handleClick = (email) => {
    if (allowedDomains.some((domain) => email.endsWith(domain))) {
      setShowAdmin(true);
    }
  };

  return (
    <div className={`floating_player w-full show px-4`}>
      <div className="flex items-center justify-between h-full">
        <div className="mr-3">
          <img
            src={blrLogo}
            alt="logo"
            className="blr_logo_player"
            onClick={() => handleClick(emailAddress)}
          />

          {/* <p className='as_text'>AudioShop</p> */}
        </div>

        <div
          className={` wave_white flex items-center w-full ${
            currentTitle ? "is_playing" : ""
          }`}
        >
          <div className="flex mx-2 mr-4">
            {/* <i className='icon-ns ns-playRoundedGreen w-40 h-40 mr-1'></i> */}
            <i
              onClick={togglePlay}
              className={`icon-ns ns-${theIcon} w-40 h-40 mr-2 ${
                !isDisabled ? "cursor-pointer" : ""
              }`}
            ></i>

            <div className="flex flex-col justify-between">
              <p id="cur_dur" className="duration_gl">
                {currentDuration}
              </p>
              <p className="duration_gl disabled">{totalDuration}</p>
            </div>
          </div>

          <div className="flex-1 wave_digs mr-2">
            <div
              id={`waveContainer`}
              className={`wave-container h-[50px] ${
                !currentAudio ? "gray_bg" : ""
              }  ${isMobile ? "mob" : ""} cursor-pointer mx-auto`}
            >
              <div
                ref={waveformRef}
                id={`waveform-global`}
                className="relative"
              ></div>

              {wsIsLoading && (
                <div className="loadingWs">
                  <Spinner />
                </div>
              )}
            </div>

            <p className="current_title line-clamp-1 break-all">
              {currentTitle}
            </p>
          </div>
        </div>

        {!searchGlobal && (
          <div className="ml-2">
            <SearchWhite
              value={searchFloating}
              onChange={(e) => setSearchFloating(e.target.value)}
              loading={sLoading}
              hideSearchbar={hideSearchbar}
              clearSearch={() => setSearchFloating("")}
            />
          </div>
        )}

        {textOnly && (
          <p
            title="Die neuesten 25 Meldungen"
            className="jigo p-2 forgotten ml-2 cursor-pointer"
            onClick={() => setShow25(true)}
            tabIndex={0}
            onKeyDown={(evt) => {
              if (evt.key === "Enter") {
                setShow25(true);
              }
            }}
          >
            25
          </p>
        )}

        <div
          className={` username_container_float ${hideSearchbar ? "min" : ""}`}
        >
          <div className="flex">
            <div
              className={` text_only ${
                textOnly ? "active" : ""
              } flex-shrink-0 mr-2`}
              onClick={() => setTextOnly(!textOnly)}
            >
              Nur Text
            </div>

            <div
              className=" flex items-center justify-center cursor-pointer "
              onClick={handleLogoutRedirect}
            >
              <i className={`icon-ns ns-logoutAs w-32 h-32`}></i>
            </div>
          </div>

          <p className="username_floating line-clamp-1">{usernameGlobal}</p>
        </div>
      </div>

      {showAdmin && (
        <Modal
          title={"BLR Audioship Live User View"}
          overlay
          onClose={() => {
            setShowAdmin(false);
            setUserCount(0);
          }}
          subHeader={userCount > 0 ? `${userCount} Users` : null}
        >
          <div className="admin-container">
            <AdminTable />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default FloatingPlayer;
