import { splitText } from "./const";

export function extractNumberFromString(str) {
    // Extract the number part from the string using a regular expression
    const match = str.match(/\d+/);
    // Check if a number is found
    if (match) {
        // Convert the matched number to a number type and return
        return parseInt(match[0], 10) - 1;
    } else {
        // If no number is found, return null or any default value you prefer
        return null;
    }
}

export function decimalToHex(decimalColor) {
    var hexColor = decimalColor.toString(16).toUpperCase();
    while (hexColor.length < 6) {
        hexColor = "0" + hexColor;
    }

    hexColor = "#" + hexColor;
    return hexColor;
}



export function secondsToTime(seconds, includeMilliseconds = true) {
    if (isNaN(seconds)) return '00:00'
    if (seconds < 0) return "00:00";
    // Calculate minutes and remaining seconds
    var minutes = Math.floor(seconds / 60);
    var remainingSeconds = Math.floor(seconds % 60);

    // Initialize milliseconds
    var milliseconds = 0;

    // Calculate milliseconds if includeMilliseconds is true
    if (includeMilliseconds) {
        milliseconds = Math.floor(((seconds % 60) - remainingSeconds) * 10);
    }

    // Format the result with leading zeros
    var formattedTime =
        (minutes < 10 ? "0" : "") +
        minutes +
        ":" +
        (remainingSeconds < 10 ? "0" : "") +
        remainingSeconds;

    // Append milliseconds if includeMilliseconds is true
    if (includeMilliseconds) {
        formattedTime += "." + milliseconds;
    }

    return formattedTime;
}


export function isJSON(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
}

// const [showSwap, setShowSwap] = useState(false)

export const showText = (text, json, showAll, width = 10, borderColor = "", isDark = false, headline = "") => {
  if (isJSON(json) && json !== null) {
    const data = JSON.parse(json);
    const first = data[0] || {};
    const opener = first.opener;
    const closing = first.closer;
    const arr = data.slice(1);

    let newsUpdate = []
    let news = []

    if (arr[0]['original']) {
      news = arr[0]['original']
    }


    if (arr[0]['update']) {
      newsUpdate = arr[0]['update']
    }

    const oneLine = <p>
      {
        news.map((item, idx) => {
          return `${item.streetCode} ${item.text?.substring(0,20)} `
        })
      }
    </p>

    if (!showAll) return oneLine
    
    return (
      <div>
        {
          opener && <p>{opener}</p>
        }
        

        {
          news.map(i => (
            <div className={`flex items-center traffic_table ${borderColor}`}>
              <div className={`code w-${width} flex-shrink-0 flex items-center justify-center`}>{i.streetCode}</div>
              <div className={`text ${borderColor} flex-1`}>{i.text}</div>
            </div>
          ))
        }
        {
          newsUpdate.map(i => (
            <div className="flex items-center traffic_table red">
              <div className={`code w-${width} flex-shrink-0 flex items-center justify-center`}>{i.streetCode}</div>
              <div className="text red flex-1">{i.text}</div>
            </div>
          ))
        }

        {
          closing && <p>{closing}</p>
        }
      </div>
    )
  } else {
    return splitText(text, isDark, headline)
  }
}
