import React from 'react';
import searchIcon from '../../assets/search_abu.svg'; // Importing the search icon
import Spinner from '../Spinner/Spinner';

const SearchWhite = ({ value, onChange, clearSearch, placeholder = "Suchen...", loading = false, hideSearchbar = false }) => {
  return (
    <div className={`search-input-w ${hideSearchbar?"hide":""}`}>
      <input
        type="text"
        className="input-field"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />

      {
        value && (
          <i
            className={`icon-ns ns-closeGray w-18 h-18 flex-shrink-0 cursor-pointer mx-2`}
            onClick={clearSearch}
          ></i>

        )
      }

      {
        loading ? (
          <span className="search-icon">
            <Spinner/>
          </span>
        ) : (
          <span className="search-icon">
            <img src={searchIcon} alt="Search Icon" />
          </span>
        )
      }
    </div>
  );
};

export default SearchWhite;
