import React from 'react';
import ReactDOM from 'react-dom/client';
import { SkeletonTheme } from "react-loading-skeleton";
// import './index.css'
import './css/tailwind.css'
import './css/style.css'
import './css/button.css'
import App from './App';

import './i18n';

//SSO
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
const msalInstance = new PublicClientApplication(msalConfig);
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  try {
    msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
  } catch (error) {
    console.log(error);
    msalInstance.logoutRedirect().catch((error) => console.log(error));
  }
}
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <SkeletonTheme baseColor="#d3d3d3" highlightColor="#C0C0C0">
      <App instance={msalInstance}/>
    </SkeletonTheme>
);

